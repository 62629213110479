import React, { Component } from "react";
import Link from "../Link";
import { withRouter } from "react-router-dom";
import "./css/style.scss";

class TableItem extends Component {
  onClickCard(url) {
    this.props.history.push(url);
  }

  render() {
    return this.props.isStay ? (
      <tr
        id="table-item-component"
        key={this.props.key}
        className="cursor-pointer"
        onClick={() => this.onClickCard(this.props.url)}
      >
        {this.props.itens.map(function(item, i) {
          return <td key={i}>{item}</td>;
        })}
        {this.props.children ? (
          <td className="align-right" key="children">
            {this.props.children}
          </td>
        ) : (
          <td key="empty" />
        )}
      </tr>
    ) : this.props.isReport ? (
      <tr id="table-item-component" key={this.props.key}>
        {/* {this.props.itens.map(function(item, i) {
          return <td key={i}>{item}</td>;
        })} */}
        <td>{this.props.itens[0]}</td>
        <td>{this.props.itens[1]}</td>
        <td>{this.props.itens[2]}</td>
        <td>{this.props.itens[3]}</td>
        <td>{this.props.itens[4]}</td>
        <td>{this.props.itens[5]}</td>
        <td>{this.props.itens[6]}</td>
        <td>{this.props.itens[7]}</td>
        {this.props.nameButton !== "" && this.props.linkButton !== "" ? (
          <td className="align-right" key="link">
            <Link
              noIcon
              link={this.props.linkButton}
              name={this.props.nameButton}
            />
          </td>
        ) : (
          <td key="empty" />
        )}
      </tr>
    ) : (
      <tr id="table-item-component" key={this.props.key}>
        {this.props.itens.map(function(item, i) {
          return <td key={i}>{item}</td>;
        })}
        {this.props.nameButton !== "" && this.props.linkButton !== "" ? (
          <td className="align-right" key="link">
            {this.props.moreLinks ?
              this.props.moreLinks
            :
              <Link noIcon link={this.props.linkButton} name={this.props.nameButton} />
            }
          </td>
        ) : (
          <td key="empty" />
        )}
      </tr>
    );
  }
}

export default withRouter(TableItem);
