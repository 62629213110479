import React, { Component } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarBurger,
  NavbarMenu,
  NavbarEnd,
  NavbarDropdown,
  NavbarLink
} from "bloomer";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import withConsumer from "../AppContext/withConsumer";
import "./css/style.scss";

// import NavLinkWithBorder from "../../components/NavLinkWithBorder";

import logo from "./img/balacobaco.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      userName: "",
      userPermission: ""
    };

    this.onClickNav = this.onClickNav.bind(this);
  }

  componentDidMount() {
    if (this.props.userAuth) {
      this.getUser(this.props.userAuth);
    }
  }

  componentDidUpdate(newProps) {
    if (newProps.userAuth !== this.props.userAuth) {
      this.getUser(newProps.userAuth);
    }
  }

  getUser = async user => {
    try {
      if (user) {
        const idToken = await user.getIdToken();
        const response = await Axios.get(
          `${process.env.REACT_APP_API_BALACOBACO_URI}/users/get/profile`,
          { headers: { Authorization: idToken } }
        );

        const nome = response.data.nome.split(" ")[0];

        this.setState({ userName: nome });

        if (response.data.scope.admin) {
          this.setState({ userPermission: "Administrador(a)" });
        } else if (response.data.scope.user_riomar) {
          this.setState({ userPermission: "Funcionário(a) Riomar" });
        } else if (response.data.scope.user_jardins) {
          this.setState({ userPermission: "Funcionário(a) Jardins" });
        } else {
          this.setState({ userPermission: "" });
        }
      } else {
        this.setState({ userName: "" });
        this.setState({ userPermission: "" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onClickNav() {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    return (
      <header>
        <Navbar>
          <Container>
            <NavbarBrand>
              <NavbarItem>
                <NavLink className="navbar-item logo" to="/">
                  <img src={logo} alt="" className="img" />
                </NavLink>
              </NavbarItem>
              <NavbarBurger
                isActive={this.state.isActive}
                onClick={this.onClickNav}
              />
            </NavbarBrand>
            <NavbarMenu
              isActive={this.state.isActive}
              onClick={this.onClickNav}
            >
              <NavLink className="navbar-item" to="/estadias">
                Estadias
              </NavLink>
              <NavLink className="navbar-item" to="/cadastros">
                Cadastros
              </NavLink>
              {/* <NavLink className="navbar-item" to="/valores">
                Valores
              </NavLink> */}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item" to="/festas">
                  Festas
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item" to="/depositos">
                  Depósitos
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item" to="/saidas">
                  Saídas
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item" to="/usuarios">
                  Usuários
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                // <NavLink className="navbar-item" to="/relatorios">
                //   Relatórios
                // </NavLink>
                <NavbarItem
                  hasDropdown
                  isHoverable
                  className="dropdown-user is-hidden-touch"
                >
                  <NavbarLink to="#!" className="is-hidden-touch item username">
                    Relatórios
                  </NavbarLink>
                  <NavbarDropdown>
                    <NavLink className="navbar-item" to="/relatorios/funcionarios">
                      <span>Relatórios dos funcionários</span>
                    </NavLink>
                    <NavLink className="navbar-item" to="/relatorios/diarios">
                      <span>Relatórios diários</span>
                    </NavLink>
                    <NavLink className="navbar-item" to="/relatorios/mensais">
                      <span>Relatórios mensais</span>
                    </NavLink>
                  </NavbarDropdown>
                </NavbarItem>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item is-hidden-desktop" to="/relatorios/funcionarios">
                  Relatórios dos funcionários
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item is-hidden-desktop" to="/relatorios/diarios">
                  Relatórios diários
                </NavLink>
              ) : null}
              {this.props.userScope.admin ? (
                <NavLink className="navbar-item is-hidden-desktop" to="/relatorios/mensais">
                  Relatórios mensais
                </NavLink>
              ) : null}
              <hr className="is-hidden-desktop" />
              <p className="is-hidden-desktop username">
                {this.state.userName}
                <br />
                <span>{this.state.userPermission}</span>
              </p>
              {!this.props.userScope.admin ? (
                <NavLink className="is-hidden-desktop navbar-item" to="/caixa">
                  Troca de caixa
                </NavLink>
              ) : null}
              <NavLink className="is-hidden-desktop navbar-item" to="/logout">
                Sair
              </NavLink>
              {/* <hr
                className="is-hidden-desktop"
                style={{ marginTop: "0px", marginBottom: "0px" }}
              />
              {!this.props.userScope.admin ?
                <NavLink className="navbar-item is-hidden-desktop" to="/estadias/cadastrar">Criar estadia</NavLink>
              :null} */}
              <NavbarEnd>
                {/* {!this.props.userScope.admin ?
                  <NavLinkWithBorder name="Criar estadia" link="/estadias/cadastrar" />
                :null} */}

                <NavbarItem
                  hasDropdown
                  isHoverable
                  className="dropdown-user is-hidden-touch"
                >
                  <NavbarLink to="#!" className="is-hidden-touch item username">
                    <p>
                      {this.state.userName}
                      <br />
                      <span>{this.state.userPermission}</span>
                    </p>
                  </NavbarLink>
                  <NavbarDropdown>
                    {!this.props.userScope.admin ? (
                      <NavLink className="navbar-item" to="/caixa">
                        <i className="material-icons icon">attach_money</i>{" "}
                        <span>Troca de caixa</span>
                      </NavLink>
                    ) : null}
                    <NavLink className="navbar-item" to="/logout">
                      <i className="material-icons icon">exit_to_app</i>{" "}
                      <span>Sair</span>
                    </NavLink>
                  </NavbarDropdown>
                </NavbarItem>

                {/* <NavLink className="navbar-item" to="/logout">
                  <p className="username">{this.state.userName}<br /><span>{this.state.userPermission}</span></p>
                  <i className="material-icons icon">exit_to_app</i>
                </NavLink> */}
              </NavbarEnd>
            </NavbarMenu>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default withConsumer(Header);
