import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: "0px !important"
  },
  cssLabel: {
    "&$cssFocused": {
      color: "rgb(100, 0, 50)"
    }
  },
  cssFocused: {},
  cssOutlinedInput: {
    backgroundColor: theme.palette.common.white,
    width: "100% !important",
    color: "black !important",
    "&$cssFocused $notchedOutline": {
      borderColor: "rgb(100, 0, 50)"
    }
  },
  notchedOutline: {}
});

function Input(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <TextField
        className={classes.margin}
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        variant="outlined"
        fullWidth
        disabled={props.disabled}
        type={props.type}
        value={props.value}
        id={"custom-css-outlined-input" + props.name}
        InputLabelProps={{
          shrink: props.shrink,
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused
          }
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline
          }
        }}
      />
    </div>
  );
}

export default withStyles(styles)(Input);
