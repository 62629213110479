import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: '0px !important'
  },
  cssLabel: {
    "&$cssFocused": {
      color: "rgb(100, 0, 50)"
    }
  },
  cssFocused: {},
  cssOutlinedInput: {
    backgroundColor: theme.palette.common.white,
    width: "100% !important",
    color: 'black !important',
    "&$cssFocused $notchedOutline": {
      borderColor: "rgb(100, 0, 50)"
    }
  },
  notchedOutline: {}
});

function MaskedInput(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <InputMask
        mask={props.mask}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {() => <TextField
          className={classes.margin}
          label={props.label}
          name={props.name}
          variant="outlined"
          fullWidth
          disabled={props.disabled}
          type={props.type}
          id="custom-css-outlined-input"

          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}

          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
        />}
      </InputMask>
    </div>
  );
}

export default withStyles(styles)(MaskedInput);