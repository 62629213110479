import React, { Component } from "react";
import Axios from "axios";
import * as moment from "moment";
import "moment/locale/pt-br";
import Chart from "react-google-charts";
import "../../css/style.scss";

import Box from "../../../../components/Box";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";

export default class DailyDetailedReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      msgError: "",
      data: "",
      dataDinheiro: "",
      dataCredito: "",
      dataDebito: "",
      dataDeposito: "",
      dataFesta: "",
      dataSaida: {
        rows: 0,
      },
      totalDinheiro: 0,
      totalCredito: 0,
      totalDebito: 0,
      totalDeposito: 0,
      totalFesta: 0,
      totalSaida: 0,
    };
  }

  async componentDidMount() {
    document.title = "Detalhes do relatório - Balacobaco";
    await this.getOutput();
    await this.getDetail();
    await this.getDeposits();
    await this.getParties();
  }
  getOutput = async () => {
    let filter = this.props.match.params.data;
    const ano  = filter.split("-")[0];
    const mes  = filter.split("-")[1];
    const dia  = filter.split("-")[2];
    filter = dia + '/' + mes + '/' + ano;
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas?filter=${filter}`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        const  data =  response.data;
        let totalSaida = 0 
        data.rows.forEach(element => {
          totalSaida = totalSaida + parseFloat(element.valor);
        });
        console.log(data)
        this.setState({dataSaida: data})
        this.setState({totalSaida})
      })
      .catch(error => {
        this.setState({ msgError: "Ocorreu algum erro ao buscar os dados do relatório. Tente novamente!" });
      });
  };
  getDetail = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/relatorios/diarios/${this.props.match.params.local}/${this.props.match.params.data}`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        const data = response.data;
        const dataDinheiro = [];
        const dataCredito = [];
        const dataDebito = [];
        let totalDinheiro = 0;
        let totalCredito = 0;
        let totalDebito = 0;

        data.estadias.forEach(element => {
          if(element.pagamento === 'dinheiro') {
            totalDinheiro = totalDinheiro + parseFloat(element.valorPago);
            dataDinheiro.push(element);
          } else if(element.pagamento === 'credito') {
            totalCredito = totalCredito + parseFloat(element.valorPago);
            dataCredito.push(element);
          } else if(element.pagamento === 'debito') {
            totalDebito = totalDebito + parseFloat(element.valorPago);
            dataDebito.push(element);
          }
        });
        
        this.setState({ data });
        this.setState({ dataDinheiro });
        this.setState({ dataCredito });
        this.setState({ dataDebito });
        this.setState({ totalDinheiro });
        this.setState({ totalCredito });
        this.setState({ totalDebito });
      })
      .catch(error => {
        this.setState({ msgError: "Ocorreu algum erro ao buscar os dados do relatório. Tente novamente!" });
      });
  };

  getDeposits = async () => {
    let filter = this.props.match.params.data;
    const ano  = filter.split("-")[0];
    const mes  = filter.split("-")[1];
    const dia  = filter.split("-")[2];
    filter = dia + '/' + mes + '/' + ano;

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/depositos?filter=${filter}&limit=10000`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        const data = response.data;
        let totalDeposito = 0;

        data.rows.forEach(element => {
          totalDeposito = totalDeposito + parseFloat(element.valor);
        });

        this.setState({ dataDeposito: data });
        this.setState({ totalDeposito });
      })
      .catch(error => {
        this.setState({ msgError: "Ocorreu algum erro ao buscar os dados do relatório. Tente novamente!" });
      });
  };

  getParties = async () => {
    let filter = this.props.match.params.data;
    const ano  = filter.split("-")[0];
    const mes  = filter.split("-")[1];
    const dia  = filter.split("-")[2];
    filter = dia + '/' + mes + '/' + ano;

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas?limit=1000000`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        const rows = [];
        let totalFesta = 0;

        response.data.rows.forEach(festa => {
          festa.pagamentos.forEach(pagamento => {
            if(filter === pagamento.dataPagamento) {
              totalFesta = totalFesta + parseFloat(pagamento.valor);
              festa['dataPagamento'] = pagamento.dataPagamento;
              festa['formaPagamento'] = pagamento.formaPagamento;
              festa['valor'] = pagamento.valor;
              rows.push(festa);
            }
          });
        });

        this.setState({ dataFesta:{ count: rows.length, rows } });
        this.setState({ totalFesta });
      })
      .catch(error => {
        this.setState({ msgError: "Ocorreu algum erro ao buscar os dados do relatório. Tente novamente!" });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  formatMoney = str => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  render() {
    return (
      <div id="daily-detailed-report-view">
        <div className="section">
          <div className="container">
            <Title title="Detalhes do relatório" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.isLoading ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      {this.state.msgError !== "" ? (
                        <p>{this.state.msgError}</p>
                      ) : (
                        <div>
                          <Subtitle>Transações em Dinheiro</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.dataDinheiro.length === 0 ?
                                <p>Nenhuma transação cadastrada em dinheiro durante o dia solicitado.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data e hora de entrada</th>
                                      <th>Data e hora de saída</th>
                                      <th>Forma de pagamento</th>
                                      {/* <th>Código transação</th> */}
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.dataDinheiro.map((data, i) => (
                                      <tr key={i}>
                                        <th>{moment(data.dataHoraEntrada).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{moment(data.dataHoraSaida).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{data.pagamento === 'debito' ? 'Débito' : (data.pagamento === 'credito' ? 'Crédito' : (data.pagamento === 'dinheiro' ? 'Dinheiro' : ''))}</th>
                                        {/* <th>{data.codigo ? data.codigo : ''}</th> */}
                                        <th>{this.formatMoney(data.valorPago)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th colSpan="3" className="bold">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.totalDinheiro)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <br />

                          <Subtitle>Transações no Crédito</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.dataCredito.length === 0 ?
                                <p>Nenhuma transação cadastrada no crédito durante o dia solicitado.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data e hora de entrada</th>
                                      <th>Data e hora de saída</th>
                                      <th>Forma de pagamento</th>
                                      {/* <th>Código transação</th> */}
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.dataCredito.map((data, i) => (
                                      <tr key={i}>
                                        <th>{moment(data.dataHoraEntrada).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{moment(data.dataHoraSaida).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{data.pagamento === 'debito' ? 'Débito' : (data.pagamento === 'credito' ? 'Crédito' : (data.pagamento === 'dinheiro' ? 'Dinheiro' : ''))}</th>
                                        {/* <th>{data.codigo ? data.codigo : ''}</th> */}
                                        <th>{this.formatMoney(data.valorPago)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th colSpan="3" className="bold">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.totalCredito)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <br />

                          <Subtitle>Transações no Débito</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.dataDebito.length === 0 ?
                                <p>Nenhuma transação cadastrada no débito durante o dia solicitado.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data e hora de entrada</th>
                                      <th>Data e hora de saída</th>
                                      <th>Forma de pagamento</th>
                                      {/* <th>Código transação</th> */}
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.dataDebito.map((data, i) => (
                                      <tr key={i}>
                                        <th>{moment(data.dataHoraEntrada).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{moment(data.dataHoraSaida).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{data.pagamento === 'debito' ? 'Débito' : (data.pagamento === 'credito' ? 'Crédito' : (data.pagamento === 'dinheiro' ? 'Dinheiro' : ''))}</th>
                                        {/* <th>{data.codigo ? data.codigo : ''}</th> */}
                                        <th>{this.formatMoney(data.valorPago)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th colSpan="3" className="bold">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.totalDebito)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <br />

                          <Subtitle>Gráfico</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-6 has-text-centered">
                              <Chart
                                width={"100%"}
                                // height={"300px"}
                                chartType="PieChart"
                                loader={<span className="is-loading-custom" />}
                                data={this.state.data.grafico}
                                options={{
                                  title: "Formas de pagamento",
                                  tooltip: {
                                    isHtml: true
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <br />

                          <Subtitle>Festas</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.dataFesta.count === 0 ?
                                <p>Nenhuma festa cadastrada durante o dia solicitado.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data da festa</th>
                                      <th>Hora</th>
                                      <th>Quantidade de crianças</th>
                                      <th>Data do pagamento</th>
                                      <th>Forma de pagamento</th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.dataFesta.rows.map((data, i) => (
                                      <tr key={i}>
                                        <th>{data.dataFesta}</th>
                                        <th>{data.horaFesta}</th>
                                        <th>{data.qtdCriancas}</th>
                                        <th>{data.dataPagamento}</th>
                                        <th>{data.formaPagamento}</th>
                                        <th>{this.formatMoney(data.valor)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th className="bold" colSpan="5">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.totalFesta)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <br />

                          <Subtitle>Depósitos</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.dataDeposito.count === 0 ?
                                <p>Nenhum depósito cadastrado durante o dia solicitado.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data</th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.dataDeposito.rows.map((data, i) => (
                                      <tr key={i}>
                                        <th>{data.dataDeposito}</th>
                                        <th>{this.formatMoney(data.valor)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th className="bold">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.totalDeposito)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>
                          <Subtitle>Saidas</Subtitle>

                          <div className="columns is-multiline">
                          <div className="column is-12">
                          {this.state.dataSaida.count === 0 ?
                            <p>Nenhuma saida cadastrado durante o período solicitado.</p>
                             :
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Data</th>
                                  <th>Valor</th>
                                  <th>Forma de saida</th>
                                  <th>Motivo</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {this.state.dataSaida.rows && this.state.dataSaida.rows.map((data, i) => (
                                  <tr key={i}>
                                  {console.log(data)}
                                    <th>{data.dataSaida}</th>
                                    <th>{this.formatMoney(data.valor)}</th>
                                    <th>{data.formaSaida}</th>
                                    <th>{data.motivo}</th>
                                  </tr>
                                ))}
                                <tr>
                                <th className="bold">Total</th>
                                <th className="bold">{this.formatMoney(this.state.totalSaida)}</th>
                                </tr>
                              </tbody>
                            </table>
                            }
                          </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}
