import React, { Component } from "react";
import auth from "../../auth";

class Logout extends Component {
  componentDidMount() {
    this.logout();
  }

  logout = async () => {
    await auth.logout();
    this.props.history.push('/');
  }

  render() {
    return (
      <></>
    );
  }
}

export default Logout;
