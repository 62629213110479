import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";
import moment from "moment";
import FileUploader from "react-firebase-file-uploader/lib/CustomUploadButton";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MaskedInput from "../../../components/Input/MaskedInput";
import Title from "../../../components/Title";
import firebase from "../../../firebase";

class EditPullout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idPullout: "",
      data: "",
      valor: "",
      motivo: "",
      formaSaida: "",
      isLoadingButton: false,
      isLoadingPage: true,
      answer: "",
      attachments: [
        {
          tipo: "",
          url: "",
        },
      ],
      url: [],
      uploadProgress: 0,
      fileInputPlaceholder: ["Carregar novo arquivo"],
      fileAnswer: [""],
    };

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  componentDidMount() {
    document.title = "Editar saída - Balacobaco";
    this.getParty();
  }

  getParty = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas/${this.props.match.params.id}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        console.log(response);
        this.setState({ attachments: response.data.anexos });
        this.setState({ idPullout: response.data.id });
        this.setState({ data: response.data.dataSaida });
        this.setState({ valor: response.data.valor });
        this.setState({ motivo: response.data.motivo });
        this.setState({ formaSaida: response.data.formaSaida });
      })
      .catch(() => {
        this.props.history.push("/saidas");
      })
      .finally(() => {
        this.setState({ isLoadingPage: false });
      });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isLoadingButton: true });

    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const data = {
      data: this.state.data,
      valor: this.state.valor,
      motivo: this.state.motivo,
      formaSaida: this.state.formaSaida,
    };

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.put(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas/${this.props.match.params.id}`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        // this.setState({
        //   answer: [
        //     <i className="material-icons" key="">
        //       check_circle_outline
        //     </i>,
        //     "Saída editada com sucesso."
        //   ]
        // });
        this.props.history.push("/saidas");
      })
      .catch(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao editar a saída. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };
  handleUploadStart = (e, i) => {
    const fileInputPlaceholder = [...this.state.fileInputPlaceholder];
    fileInputPlaceholder[i] = 0;
    this.setState({ fileInputPlaceholder, uploadProgress: 0 });
  };
  handleProgress = (progress, i) => {
    const fileInputPlaceholder = [...this.state.fileInputPlaceholder];
    fileInputPlaceholder[i] = `Enviando... | ${progress}%`;
    this.setState({ fileInputPlaceholder, uploadProgress: progress });
  };
  handleUploadError = (error, i) => {
    const fileAnswer = [...this.state.fileAnswer];
    fileAnswer[i] = [
      <i className="material-icons" key="">
        error_outline
      </i>,
      "Não foi possível enviar o anexo. Tente novamente!",
    ];
    this.setState({ fileAnswer });
    console.error(error);
  };

  handleUploadSuccess = async (filename, i) => {
    const idToken = await this.props.userAuth.getIdToken();
    let fileInputPlaceholder = [...this.state.fileInputPlaceholder];

    if (filename.length > 30) {
      fileInputPlaceholder[i] = `${filename.substring(16, 46)}...`;
    } else {
      fileInputPlaceholder[i] = filename.substring(16);
    }

    this.setState({ fileInputPlaceholder });

    let url = await firebase
      .storage()
      .ref("anexos")
      .child(filename)
      .getDownloadURL();
    const attachments = [...this.state.attachments];
    attachments.push({
      tipo: filename,
      url,
    });
    const data = {
      url,
      tipo: filename,
      idSaida: this.state.idPullout,
    };
    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/anexos`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.setState({ isLoading: true });
        this.getParty();
      })
      .catch((error) => {
        console.log(error);
      });

    fileInputPlaceholder = ["Carregar novo arquivo"];
    this.setState({ attachments, fileInputPlaceholder });
  };

  removeAttachment = async (e, i) => {
    e.preventDefault();

    const attachments = [...this.state.attachments];
    const arquivo = this.state.attachments[i];
    await firebase.storage().ref("anexos").child(arquivo.tipo).delete();
    attachments.splice(i, 1);
    const fileInputPlaceholder = ["Carregar novo arquivo"];
    this.setState({ attachments, fileInputPlaceholder });
  };

  deleteAttachment = async (idToDelete, i) => {
    const idToken = await this.props.userAuth.getIdToken();
    const attachments = [...this.state.attachments];
    const arquivo = this.state.attachments[i];
    await firebase.storage().ref("anexos").child(arquivo.tipo).delete();
    attachments.splice(i, 1);
    await Axios.delete(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/anexos/${idToDelete}`,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.setState({ isLoading: true });
        this.getParty();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  createUIAttachments = () => {
    // this.state.attachments.map((el, i) => (
    let i = 0;

    return (
      <div key={i} className="attachment">
        <div className="attachment-div">
          <FileUploader
            accept="*"
            name="fileName"
            // ref={instance => {this.fileUploader = instance;}}
            storageRef={firebase.storage().ref("anexos")}
            onUploadStart={(e) => this.handleUploadStart(e, i)}
            onUploadError={(e) => this.handleUploadError(e, i)}
            onUploadSuccess={(e) => this.handleUploadSuccess(e, i)}
            onProgress={(e) => this.handleProgress(e, i)}
            filename={(file) =>
              `${moment().format("YYYYMMDDHHmmss")}_${file.name}`
            }
            multiple
            style={{
              flex: "1",
              backgroundColor: "transparent",
              border: "1.5px solid #640032",
              color: "#640032",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
              fontSize: "14px",
              height: "auto",
              fontWeight: "bold",
              padding: "18px 20px",
              textAlign: "center",
              textTransform: "uppercase",
              transition: "0.2s ease-in",
              fontFamily: "Open Sans",
              cursor: "pointer",
            }}
          >
            <i className="material-icons" style={{ verticalAlign: "-6px" }}>
              attach_file
            </i>
            ESCOLHA UM ARQUIVO
          </FileUploader>
          <div className="filename">{this.state.fileInputPlaceholder[i]}</div>
          {this.state.attachments.length > 1 ? (
            <button
              type="button"
              className="remove-attachment-register"
              onClick={(e) => this.removeAttachment(e, i)}
            >
              <i className="material-icons">highlight_off</i>
            </button>
          ) : null}
        </div>

        <div className="answer">{this.state.fileAnswer}</div>
      </div>
    );
    // ));
  };
  render() {
    return (
      <div id="edit-pullout-view">
        <div className="section">
          <div className="container">
            <Title title="Editar saída" />
            <Box>
              {this.state.isLoadingPage ? (
                <span className="is-loading-custom" />
              ) : (
                <div className="columns">
                  <div className="column">
                    <form onSubmit={this.handleSubmit}>
                      <div className="columns is-multiline">
                        <div className="column is-6 field">
                          <MaskedInput
                            name="data"
                            label="Data *"
                            type="text"
                            value={this.state.data}
                            onChange={this.handleFormChange}
                            mask="99/99/9999"
                          />
                          <span>
                            {this.validator.message(
                              "data",
                              this.state.data,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="valor"
                            label="Valor *"
                            type="number"
                            value={this.state.valor}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "valor",
                              this.state.valor,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="motivo"
                            label="Motivo *"
                            type="text"
                            value={this.state.motivo ? this.state.motivo : ""}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "motivo",
                              this.state.motivo,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="formaSaida"
                            label="Forma da Saída *"
                            type="text"
                            value={
                              this.state.formaSaida ? this.state.formaSaida : ""
                            }
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "formaSaida",
                              this.state.formaSaida,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="column is-6 field">
                        {this.createUIAttachments()}
                        {this.state.attachments.filter((a, i) => a.url !== "")
                          .length > 0 ? (
                          <div>
                            <Box className="box-bottom">
                              <div className="columns is-multiline">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Arquivos</th>
                                      <th className="text-right">Remover</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.attachments.map(
                                      (attachment, i) =>
                                        attachment.url !== "" && (
                                          <tr key={i}>
                                            <td className="link">
                                              <a
                                                href={attachment.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <i
                                                  className="material-icons"
                                                  style={{
                                                    verticalAlign: "-6px",
                                                  }}
                                                >
                                                  attach_file
                                                </i>{" "}
                                                {attachment.tipo}
                                              </a>
                                            </td>
                                            <td className="text-right">
                                              <button
                                                type="button"
                                                className="remove-button remove-attachment"
                                                onClick={(e) => {
                                                  this.deleteAttachment(
                                                    attachment.id,
                                                    i
                                                  );
                                                }}
                                              >
                                                <i className="material-icons">
                                                  delete
                                                </i>
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Box>
                          </div>
                        ) : null}
                      </div>
                      <div className="columns is-multiline has-text-centered">
                        <div className="column field">
                          <div className="answer">{this.state.answer}</div>
                        </div>
                      </div>

                      <div className="columns is-multiline is-centered">
                        <div className="column is-2 field">
                          <Button name="Voltar" link="/saidas" isWhite />
                        </div>
                        <div className="column is-2 field">
                          <Button
                            name="Salvar"
                            type="submit"
                            isLoading={this.state.isLoadingButton}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPullout;
