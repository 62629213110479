import React from "react";

const Pagination = (props) => (
  <div>
    {props.totalResults > 10 ? (
      <div
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <ul className="pagination-list">
          {props.offset > 0 ? (
            <li>
              <button
                type="button"
                className="pagination-link not-border efeito-button"
                onClick={props.previousPage}
              >
                <i className="material-icons">keyboard_arrow_left</i>
              </button>
            </li>
          ) : null}
          {props.currentPage > 2 ? (
            <li>
              <button
                type="button"
                className="pagination-link efeito-button"
                aria-current="page"
                onClick={() => {
                  props.goToPage(props.offset - 20, props.currentPage - 2);
                }}
              >
                {props.currentPage - 2}
              </button>
            </li>
          ) : null}
          {props.currentPage > 1 ? (
            <li>
              <button
                type="button"
                className="pagination-link efeito-button"
                aria-current="page"
                onClick={() => {
                  props.goToPage(props.offset - 10, props.currentPage - 1);
                }}
              >
                {props.currentPage - 1}
              </button>
            </li>
          ) : null}
          <li>
            <button
              type="button"
              className="pagination-link is-current efeito-button"
              aria-current="page"
              onClick={() => {
                props.goToPage(props.offset, props.currentPage);
              }}
            >
              {props.currentPage}
            </button>
          </li>
          {props.offset + 10 < props.totalResults ? (
            <li>
              <button
                type="button"
                className="pagination-link efeito-button"
                aria-current="page"
                onClick={() => {
                  props.goToPage(props.offset + 10, props.currentPage + 1);
                }}
              >
                {props.currentPage + 1}
              </button>
            </li>
          ) : null}
          {props.offset + 20 < props.totalResults ? (
            <li>
              <button
                type="button"
                className="pagination-link efeito-button"
                aria-current="page"
                onClick={() => {
                  props.goToPage(props.offset + 20, props.currentPage + 2);
                }}
              >
                {props.currentPage + 2}
              </button>
            </li>
          ) : null}
          {props.offset + 10 < props.totalResults ? (
            <li>
              <button
                type="button"
                className="pagination-link not-border"
                onClick={props.nextPage}
              >
                <i className="material-icons">keyboard_arrow_right</i>
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    ) : null}
  </div>
);

export default Pagination;
