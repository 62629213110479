import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MaskedInput from "../../../components/Input/MaskedInput";
import Title from "../../../components/Title";

class RegisterDeposit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      valor: "",
      isLoadingButton: false,
      answer: ""
    };

    this.validator = new SimpleReactValidator({
      locale: 'en',
      messages: {
        default: "Campo obrigatório"
      }
    });
  }
  componentDidMount() {
    document.title = "Adicionar novo depósito - Balacobaco";
  }
  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoadingButton: true });
    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos."
        ]
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const data = {
      data: this.state.data,
      valor: this.state.valor,
    };

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.post(`${process.env.REACT_APP_API_BALACOBACO_URI}/depositos`, data, { headers: { Authorization: idToken } })
      .then(() => {
        // this.setState({
        //   answer: [
        //     <i className="material-icons" key="">
        //       check_circle_outline
        //     </i>,
        //     "Depósito cadastrado com sucesso."
        //   ]
        // });
        this.props.history.push("/depositos");
      })
      .catch(err => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao cadastrar o depósito. Tente novamente!"
          ]
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  render() {
    return (
      <div id="register-party-view">
        <div className="section">
          <div className="container">
            <Title title="Adicionar novo depósito" />
            <Box>
              <div className="columns">
                <div className="column">
                  <form onSubmit={this.handleSubmit}>
                    <div className="columns is-multiline">
                      <div className="column is-6 field">
                        <MaskedInput
                          name="data"
                          label="Data *"
                          type="text"
                          value={this.state.data}
                          onChange={this.handleFormChange}
                          mask="99/99/9999"
                        />
                        <span>
                          {this.validator.message(
                            "data",
                            this.state.data,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-6 field">
                        <Input
                          name="valor"
                          label="Valor *"
                          type="number"
                          value={this.state.valor}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "valor",
                            this.state.valor,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="columns is-multiline has-text-centered">
                      <div className="column field">
                        <div className="answer">{this.state.answer}</div>
                      </div>
                    </div>

                    <div className="columns is-multiline is-centered">
                      <div className="column is-2 field">
                        <Button name="Voltar" link="/depositos" isWhite />
                      </div>
                      <div className="column is-2 field">
                        <Button
                          name="Salvar"
                          type="submit"
                          isLoading={this.state.isLoadingButton}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterDeposit;
