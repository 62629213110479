import React from "react";
import PropTypes from "prop-types";
import Clock from "react-live-clock";

import "./css/style.scss";

const Title = props => {
  return (
    <div id="title-component">
      {!props.withData ? (
        <div>
          <div className="columns marginBottom">
            <div className="column">
              <h1>{props.title}</h1>
            </div>
          </div>
          <hr className="divisor" />
        </div>
      ) : (
        <div>
          <div className="columns marginBottom">
            <div className="column is-2 is-1-widescreen">
              <h1>{props.title}</h1>
            </div>
            <div className="column is-2">
              <p className="subtitle">
                Data:{" "}
                <span className="subtitle-data">{props.date}</span>
              </p>
            </div>
            <div className="column is-2">
              {/* <p className="subtitle">Horário: {props.time}</p> */}
              <p className="subtitle">
                Horário:{" "}
                <span className="subtitle-data">
                  <Clock
                    style={{ fontSize: "14px" }}
                    format={"HH:mm:ss"}
                    ticking={true}
                    timezone={"America/Bahia"}
                  />
                </span>
              </p>
            </div>
            {/* <div className="column is-1">
              <p className="subtitle">
                Total: <span className="subtitle-data">{props.total}</span>
              </p>
            </div> */}
          </div>
          <hr className="divisor" />
        </div>
      )}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired
};

export default Title;
