import React from "react";
import "./css/style.scss";

import Button from "../../components/Button";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "404 - Balacobaco";
  }

  render() {
    return (
      <div id="not-found-view">
        <div className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="error">
                  <h1>Error 404</h1>
                  <p>A página solicitada não existe.</p>

                  <div className="columns">
                    <div className="column is-2 field">
                      <Button name="Ir para Home" link="/" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
