import React from 'react';
import PropTypes from 'prop-types';
import './css/style.scss';

const SearchBar = (props) => {
  return (
    <div id="search-bar">
      <div className="columns">
        <div className="column is-5">
          <form onSubmit={props.onSubmit}>
            <div className="field has-addons">
              <div className="control size-field">
                <input
                  className="input"
                  type="text"
                  name="searchBar"
                  placeholder={props.placeholder}
                  onChange={props.onChange}
                  value={props.value}
                />
              </div>
              <div className="control">
                <button type="submit" className="button btn">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string
}

export default SearchBar;