import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Recaptcha from "react-recaptcha";
import firebase from "../../../firebase";
import withConsumer from "../../../components/AppContext/withConsumer";
import "../css/style.scss";

import Button from "../../../components/Button";
import Input from "../../../components/Input";

import logo from "../../../assets/img/balacobaco_web_logo.png";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      answer: "",
      isValidRecaptcha: false,
      isLoadingButton: false
    };

    this.validator = new SimpleReactValidator({
      locale: 'en',
      messages: {
        default: "Campo obrigatório"
      }
    });
  }

  componentDidMount() {
    document.title = "Resetar senha  - Balacobaco";
  }

  verifyCallback = () => {
    this.setState({ isValidRecaptcha: true });
    this.setState({ answer: "" });
  };

  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ answer: "" });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoadingButton: true });

    this.setState({ answer: "" });

    if (!this.state.isValidRecaptcha) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Efetue a verificação do robô."
        ]
      });
      this.setState({ isLoadingButton: false });
      return;
    }

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos."
        ]
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    firebase
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              check_circle_outline
            </i>,
            "Se o e-mail existir em nosso sistema, será enviada uma solicitação de nova senha."
          ]
        });
        this.setState({ isLoadingButton: false });
      })
      .catch(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              check_circle_outline
            </i>,
            "Se o e-mail existir em nosso sistema, será enviada uma solicitação de nova senha."
          ]
        });
        this.setState({ isLoadingButton: false });
      });
  };

  render() {
    const { verifyCallback } = this;

    return (
      <div id="forgot-password-view">
        <div className="section">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="" />
            </div>

            <div className="columns is-centered">
              <div className="column is-8-tablet is-6-desktop">
                <div className="box-round">
                  <form onSubmit={this.handleSubmit}>
                    <div className="field">
                      <Input
                        label="E-mail"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleFormChange}
                      />
                      <span>
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required"
                        )}
                      </span>
                    </div>

                    <div className="recaptcha margin-input">
                      <Recaptcha
                        sitekey="6Le0tpIUAAAAAG5pQ7Fp6KrytAFGwGm3mEeL1ZB5"
                        render="explicit"
                        verifyCallback={verifyCallback}
                        hl="pt-BR"
                        required
                      />
                    </div>

                    <div className="answer margin-input">
                      {this.state.answer}
                    </div>

                    <div className="field margin-input btn">
                      <Button
                        name="Entrar"
                        type="submit"
                        isLoading={this.state.isLoadingButton}
                      />
                    </div>

                    <div className="field margin-other forgot">
                      <NavLink to="/">Possui senha? Faça login</NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withConsumer(ForgotPassword);
