import React, { Component } from "react";
import Axios from "axios";
import * as moment from "moment";
import "moment/locale/pt-br";
import Chart from "react-google-charts";
import "../../css/style.scss";

import Box from "../../../../components/Box";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";

export default class EmployeeDetailedReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      msgError: "",
      data: ""
    };
  }

  componentDidMount() {
    document.title = "Detalhes do relatório - Balacobaco";
    this.getDetail();
  }

  getDetail = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/relatorios/funcionarios/${
        this.props.match.params.id
      }`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        this.setState({ data: response.data });
      })
      .catch(error => {
        this.setState({ msgError: "Ocorreu algum erro ao buscar os dados do relatório. Tente novamente!" });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  formatMoney = str => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  render() {
    return (
      <div id="employee-detailed-report-view">
        <div className="section">
          <div className="container">
            <Title title="Detalhes do relatório" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.isLoading ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      {this.state.msgError !== "" ? (
                        <p>{this.state.msgError}</p>
                      ) : (
                        <div>
                          <Subtitle>Caixa</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-3">
                              <p className="label-details">Data de abertura:</p>
                              <p className="answer-details">
                                {moment(
                                  this.state.data.caixa.dataAbertura
                                ).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">Hora de abertura:</p>
                              <p className="answer-details">
                                {moment(
                                  this.state.data.caixa.dataAbertura
                                ).format("HH:mm")}
                                h
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">
                                Data de fechamento:
                              </p>
                              <p className="answer-details">
                                {moment(
                                  this.state.data.caixa.dataFechamento
                                ).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">
                                Hora de fechamento:
                              </p>
                              <p className="answer-details">
                                {moment(
                                  this.state.data.caixa.dataFechamento
                                ).format("HH:mm")}
                                h
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">
                                Caixa aberto com:
                              </p>
                              <p className="answer-details">
                                {this.formatMoney(
                                  this.state.data.caixa.valorAbertura
                                )}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">
                                Caixa fechado com:
                              </p>
                              <p className="answer-details">
                                {this.formatMoney(
                                  this.state.data.caixa.valorFechamento
                                )}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">Saldo do caixa:</p>
                              <p className="answer-details">
                                {this.formatMoney(this.state.data.caixa.saldoCaixa)}
                              </p>
                            </div>

                            {/* <div className="column is-3">
                              <p className="label-details">
                                Total em dinheiro das estadias no período:
                              </p>
                              <p className="answer-details">
                                {this.formatMoney(
                                  this.state.data.caixa.estadiasDinheiro
                                )}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">
                                Diferença no caixa:
                              </p>
                              <p className="answer-details">
                                {this.formatMoney(
                                  this.state.data.caixa.diferencaSaldoEstadia
                                )}
                              </p>
                            </div> */}

                            <div className="column is-3">
                              <p className="label-details">Usuário:</p>
                              <p className="answer-details">
                                {this.state.data.caixa.user.nome}
                              </p>
                            </div>

                            <div className="column is-3">
                              <p className="label-details">Local:</p>
                              <p className="answer-details">
                                {this.state.data.caixa.user.scope
                                  ? this.state.data.caixa.user.scope
                                      .user_jardins
                                    ? "Jardins"
                                    : this.state.data.caixa.user.scope
                                        .user_riomar
                                    ? "Riomar"
                                    : ""
                                  : ""}
                              </p>
                            </div>
                          </div>

                          <br />

                          <Subtitle>Transações</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-12">
                              {this.state.data.estadias.length === 0 ?
                                <p>Nenhuma transação cadastrada durante essa estadia.</p>
                              :
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Data e hora de entrada</th>
                                      <th>Data e hora de saída</th>
                                      <th>Forma de pagamento</th>
                                      {/* <th>Código transação</th> */}
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.data.estadias.map((data, i) => (
                                      <tr key={i}>
                                        <th>{moment(data.dataHoraEntrada).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{moment(data.dataHoraSaida).format("DD/MM/YYYY HH:mm:ss")}h</th>
                                        <th>{data.pagamento === 'debito' ? 'Débito' : (data.pagamento === 'credito' ? 'Crédito' : (data.pagamento === 'dinheiro' ? 'Dinheiro' : ''))}</th>
                                        {/* <th>{data.codigo ? data.codigo : ''}</th> */}
                                        <th>{this.formatMoney(data.valorPago)}</th>
                                      </tr>
                                    ))}
                                    <tr>
                                      <th colSpan="3" className="bold">Total</th>
                                      <th className="bold">{this.formatMoney(this.state.data.valor_total_estadias)}</th>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            </div>
                          </div>

                          <br />

                          <Subtitle>Gráfico</Subtitle>

                          <div className="columns is-multiline">
                            <div className="column is-6 has-text-centered">
                              <Chart
                                width={"100%"}
                                // height={"300px"}
                                chartType="PieChart"
                                loader={<span className="is-loading-custom" />}
                                data={this.state.data.total_estadias}
                                options={{
                                  title: "Formas de pagamento",
                                  tooltip: {
                                    isHtml: true
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}
