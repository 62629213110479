import React, { Component } from "react";
import Axios from "axios";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Pagination from "../../../components/Pagination";
import TableItem from "../../../components/TableItem";
import Title from "../../../components/Title";

import moment from "moment";
import "moment/locale/pt-br";
import "../css/style.scss";

export default class EmployeeReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: true,
      msgError: "",
      listReports: [],
      filterIsOpen: false,
      locationsList: [
        { label: "Shopping Jardins", value: "user_jardins" },
        { label: "Shopping Riomar", value: "user_riomar" },
      ],
      location: "",
      usersList: [],
      user: "",
      dataFechamento: "",

      offset: 0,
      currentPage: 1,
      totalResults: 0,
    };
  }

  componentDidMount() {
    document.title = "Relatórios dos funcionários - Balacobaco";
    this.getUsers();
    this.getReport();
  }

  getUsers = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(`${process.env.REACT_APP_API_BALACOBACO_URI}/users`, {
      headers: { Authorization: idToken },
    })
      .then((response) => {
        let usersList = [];
        response.data.data.rows.map((user) => {
          user.label = user.nome;
          user.value = user.id;
          usersList.push(user);
          return null;
        });
        this.setState({ usersList });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getReport = async (filter = "") => {
    this.setState({ msgError: "" });

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/relatorios/funcionarios?init=${this.state.offset}&qtd=10${filter}`,
      {
        headers: { Authorization: idToken },
      }
    )
      .then((response) => {
        if (response.data.length === 0) {
          this.setState({ msgError: "Nenhum valor encontrado." });
        } else {
          let listReports = [];
          if (response.data.result.length === 0) {
            this.setState({
              msgError: "Nenhum dado cadastrado até o momento!",
            });
          } else {
            response.data.result.map((item) => {
              listReports.push({
                id: item.id,
                usuario: item.usuario,
                local: item.scope
                  ? item.scope.user_jardins
                    ? "Jardins"
                    : item.scope.user_riomar
                    ? "Riomar"
                    : ""
                  : "",
                dataFechamento: moment(item.dataFechamento).format(
                  "DD/MM/YYYY"
                ),
                valorAbertura: this.formatMoney(item.valorAbertura),
                valorFechamento: this.formatMoney(item.valorFechamento),
                saldoCaixa: this.formatMoney(item.saldoCaixa),
                // somaEstadias: this.formatMoney(item.somaEstadias),
                // diferencaSaldoEstadia: this.formatMoney(item.diferencaSaldoEstadia)
              });

              return true;
            });

            this.setState({ listReports });
            this.setState({ totalResults: response.data.count || 0 });
          }
        }
      })
      .catch(() => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca do relatório. Tente novamente!",
        });
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleFilter = () => {
    this.setState({ filterIsOpen: !this.state.filterIsOpen });
  };

  formatMoney = (str) => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingTable: true });

    let filter = "";

    if (this.state.user !== "") {
      filter += "&idUser=" + this.state.user;
    }

    if (this.state.location !== "") {
      filter += "&scope=" + this.state.location;
    }

    if (this.state.dataFechamento !== "") {
      filter += "&dataFechamento=" + this.state.dataFechamento;
    }

    this.getReport(filter);
  };

  clearFilter = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingTable: true });
    this.setState({ user: "" });
    this.setState({ location: "" });
    this.setState({ dataFechamento: "" });
    this.getReport();
  };

  // Paginação

  previousPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset - 10 });
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.getReport();
    window.scrollTo(0, 0);
  };

  goToPage = (page, currentPage) => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: page });
    this.setState({ currentPage: currentPage });
    this.getReport();
    window.scrollTo(0, 0);
  };

  nextPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset + 10 });
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.getReport();
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div id="employee-reports-view">
        <div className="section">
          <div className="container">
            <Title title="Relatórios dos funcionários" />

            <div className="columns">
              <div className="column">
                <button
                  type="button"
                  className="filter efeito-button"
                  onClick={() => this.toggleFilter()}
                >
                  Filtros
                  {this.state.filterIsOpen ? (
                    <i className="material-icons filter-icon">arrow_drop_up</i>
                  ) : (
                    <i className="material-icons filter-icon">
                      arrow_drop_down
                    </i>
                  )}
                </button>
              </div>
            </div>

            {this.state.filterIsOpen ? (
              <Box className="filter-margin">
                <form onSubmit={this.handleSubmit}>
                  <div className="columns">
                    <div className="column is-4 field">
                      <Select
                        label="Usuário"
                        name="user"
                        value={this.state.user}
                        options={this.state.usersList}
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="column is-4 field">
                      <Select
                        label="Local"
                        name="location"
                        value={this.state.location}
                        options={this.state.locationsList}
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="column is-4 field">
                      <Input
                        name="dataFechamento"
                        label="Data"
                        type="date"
                        value={this.state.dataFechamento}
                        onChange={this.handleFormChange}
                        shrink={true}
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-8" />
                    <div className="column filter-btn is-2">
                      <Button
                        name="Limpar"
                        isWhite
                        type="button"
                        onClick={this.clearFilter}
                      />
                    </div>
                    <div className="column filter-btn is-2">
                      <Button name="Filtrar" type="submit" />
                    </div>
                  </div>
                </form>
              </Box>
            ) : null}

            <div className="columns">
              <div className="column">
                {this.state.isLoadingTable ? (
                  <span className="is-loading-custom" />
                ) : (
                  <div>
                    {this.state.msgError !== "" ? (
                      <div className="error">
                        <p>{this.state.msgError}</p>
                      </div>
                    ) : (
                      <table
                        style={
                          this.props.userScope.admin
                            ? { marginTop: "33px" }
                            : { marginTop: "10px" }
                        }
                      >
                        <thead>
                          <tr>
                            <th>Funcionário(a)</th>
                            <th>Local</th>
                            <th>Data Fechamento</th>
                            <th>Caixa Aberto</th>
                            <th>Caixa Fechado</th>
                            <th>Saldo Caixa</th>
                            {/* <th>Estadias</th> */}
                            {/* <th>Diferença no Caixa</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listReports.map((item, i) => (
                            <TableItem
                              isReport
                              key={item.id}
                              itens={[
                                item.usuario,
                                item.local,
                                item.dataFechamento,
                                item.valorAbertura,
                                item.valorFechamento,
                                item.saldoCaixa,
                                // item.somaEstadias,
                                // item.diferencaSaldoEstadia
                              ]}
                              nameButton={
                                <i className="material-icons icon-view">
                                  pageview
                                </i>
                              }
                              linkButton={
                                this.props.userScope.admin
                                  ? "/relatorios/funcionarios/" + item.id
                                  : ""
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    )}

                    <Pagination
                      offset={this.state.offset}
                      currentPage={this.state.currentPage}
                      totalResults={this.state.totalResults}
                      previousPage={this.previousPage}
                      goToPage={this.goToPage}
                      nextPage={this.nextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
