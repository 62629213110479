import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import moment from "moment";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MaskedInput from "../../../components/Input/MaskedInput";
import Title from "../../../components/Title";
import FileUploader from "react-firebase-file-uploader/lib/CustomUploadButton";
import firebase from "../../../firebase";

class RegisterPullout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      valor: "",
      motivo: "",
      formaSaida: "",
      isLoadingButton: false,
      answer: "",
      attachments: [
        {
          tipo: "",
          url: "",
        },
      ],
      url: [],
      uploadProgress: 0,
      fileInputPlaceholder: ["Carregar novo arquivo"],
      fileAnswer: [""],
    };

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }
  componentDidMount() {
    document.title = "Adicionar nova saída - Balacobaco";
  }
  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //console.log(e.target)
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingButton: true });
    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const data = {
      data: this.state.data,
      valor: this.state.valor,
      motivo: this.state.motivo,
      formaSaida: this.state.formaSaida,
      anexos: this.state.attachments,
    };
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.props.history.push("/saidas");
      })
      .catch((err) => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao cadastrar a saída. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };
  // =================== UPLOAD DE ARQUIVOS - FIM
  handleUploadStart = (e, i) => {
    const fileInputPlaceholder = [...this.state.fileInputPlaceholder];
    fileInputPlaceholder[i] = 0;
    this.setState({ fileInputPlaceholder, uploadProgress: 0 });
  };
  handleProgress = (progress, i) => {
    const fileInputPlaceholder = [...this.state.fileInputPlaceholder];
    fileInputPlaceholder[i] = `Enviando... | ${progress}%`;
    this.setState({ fileInputPlaceholder, uploadProgress: progress });
  };
  handleUploadError = (error, i) => {
    const fileAnswer = [...this.state.fileAnswer];
    fileAnswer[i] = [
      <i className="material-icons" key="">
        error_outline
      </i>,
      "Não foi possível enviar o anexo. Tente novamente!",
    ];
    this.setState({ fileAnswer });
    console.error(error);
  };

  handleUploadSuccess = async (filename, i) => {
    let fileInputPlaceholder = [...this.state.fileInputPlaceholder];

    if (filename.length > 30) {
      fileInputPlaceholder[i] = `${filename.substring(16, 46)}...`;
    } else {
      fileInputPlaceholder[i] = filename.substring(16);
    }

    this.setState({ fileInputPlaceholder });

    let url = await firebase
      .storage()
      .ref("anexos")
      .child(filename)
      .getDownloadURL();
    const attachments = [...this.state.attachments];
    attachments.push({
      tipo: filename,
      url,
    });
    fileInputPlaceholder = ["Carregar novo arquivo"];
    this.setState({ attachments, fileInputPlaceholder });
  };

  removeAttachment = async (e, i) => {
    e.preventDefault();

    const attachments = [...this.state.attachments];
    const arquivo = this.state.attachments[i];
    await firebase.storage().ref("anexos").child(arquivo.tipo).delete();
    attachments.splice(i, 1);
    const fileInputPlaceholder = ["Carregar novo arquivo"];
    this.setState({ attachments, fileInputPlaceholder });
  };

  createUIAttachments = () => {
    // this.state.attachments.map((el, i) => (
    let i = 0;

    return (
      <div key={i} className="attachment">
        <div className="attachment-div">
          <FileUploader
            accept="*"
            name="fileName"
            // ref={instance => {this.fileUploader = instance;}}
            storageRef={firebase.storage().ref("anexos")}
            onUploadStart={(e) => this.handleUploadStart(e, i)}
            onUploadError={(e) => this.handleUploadError(e, i)}
            onUploadSuccess={(e) => this.handleUploadSuccess(e, i)}
            onProgress={(e) => this.handleProgress(e, i)}
            filename={(file) =>
              `${moment().format("YYYYMMDDHHmmss")}_${file.name}`
            }
            multiple
            style={{
              flex: "1",
              backgroundColor: "transparent",
              border: "1.5px solid #640032",
              color: "#640032",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
              fontSize: "14px",
              height: "auto",
              fontWeight: "bold",
              padding: "18px 20px",
              textAlign: "center",
              textTransform: "uppercase",
              transition: "0.2s ease-in",
              fontFamily: "Open Sans",
              cursor: "pointer",
            }}
          >
            <i className="material-icons" style={{ verticalAlign: "-6px" }}>
              attach_file
            </i>
            ESCOLHA UM ARQUIVO
          </FileUploader>
          <div className="filename">{this.state.fileInputPlaceholder[i]}</div>
          {this.state.attachments.length > 1 ? (
            <button
              type="button"
              className="remove-attachment-register"
              onClick={(e) => this.removeAttachment(e, i)}
            >
              <i className="material-icons">highlight_off</i>
            </button>
          ) : null}
        </div>

        <div className="answer">{this.state.fileAnswer}</div>
      </div>
    );
    // ));
  };
  render() {
    return (
      <div id="register-pullout-view">
        <div className="section">
          <div className="container">
            <Title title="Adicionar nova saída" />
            <Box>
              <div className="columns">
                <div className="column">
                  <form onSubmit={this.handleSubmit}>
                    <div className="columns is-multiline">
                      <div className="column is-6 field">
                        <MaskedInput
                          name="data"
                          label="Data *"
                          type="text"
                          value={this.state.data}
                          onChange={this.handleFormChange}
                          mask="99/99/9999"
                        />
                        <span>
                          {this.validator.message(
                            "data",
                            this.state.data,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-6 field">
                        <Input
                          name="valor"
                          label="Valor *"
                          type="number"
                          value={this.state.valor}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "valor",
                            this.state.valor,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-6 field">
                        <Input
                          name="motivo"
                          label="Motivo *"
                          type="text"
                          value={this.state.motivo}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "motivo",
                            this.state.motivo,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-6 field">
                        <Input
                          name="formaSaida"
                          label="Forma da Saída *"
                          type="text"
                          value={this.state.formaSaida}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "formaSaida",
                            this.state.formaSaida,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="column is-6 field">
                      {this.createUIAttachments()}
                      {this.state.attachments.filter((a, i) => a.url !== "")
                        .length > 0 ? (
                        <div>
                          <Box className="box-bottom">
                            <div className="columns is-multiline">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Arquivos</th>
                                    <th className="text-right">Remover</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.attachments.map(
                                    (attachment, i) =>
                                      attachment.url !== "" && (
                                        <tr key={i}>
                                          <td className="link">
                                            <a
                                              href={attachment.url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i
                                                className="material-icons"
                                                style={{
                                                  verticalAlign: "-6px",
                                                }}
                                              >
                                                attach_file
                                              </i>{" "}
                                              {attachment.tipo}
                                            </a>
                                          </td>
                                          <td className="text-right">
                                            <button
                                              type="button"
                                              className="remove-button remove-attachment"
                                              onClick={(e) => {
                                                this.removeAttachment(e, i);
                                              }}
                                            >
                                              <i className="material-icons">
                                                delete
                                              </i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Box>
                        </div>
                      ) : null}
                    </div>
                    <div className="columns is-multiline has-text-centered">
                      <div className="column field">
                        <div className="answer">{this.state.answer}</div>
                      </div>
                    </div>

                    <div className="columns is-multiline is-centered">
                      <div className="column is-2 field">
                        <Button name="Voltar" link="/saidas" isWhite />
                      </div>
                      <div className="column is-2 field">
                        <Button
                          name="Salvar"
                          type="submit"
                          isLoading={this.state.isLoadingButton}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPullout;
