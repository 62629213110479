import React, { Component } from "react";
import Axios from "axios";

import Link from "../../components/Link";
import TableItem from "../../components/TableItem";
import Title from "../../components/Title";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: true,
      msgError: "",
      listResponse: []
    };
  }

  componentDidMount() {
    document.title = "Usuários - Balacobaco";
    this.getUsers();
  }

  getUsers = async () => {
    const idToken = await this.props.userAuth.getIdToken();

    await Axios.get(`${process.env.REACT_APP_API_BALACOBACO_URI}/users`, {
      headers: { Authorization: idToken }
    })
      .then(response => {
        if (response.data.data.count === 0) {
          this.setState({
            msgError: "Nenhum usuário cadastrado até o momento."
          });
        }
        this.setState({ listResponse: response.data.data.rows });
      })
      .catch(error => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca dos usuários. Tente novamente!"
        });
        console.log(error);
      });

    this.setState({ isLoadingTable: false });
  };

  render() {
    return (
      <div id="users-view">
        <div className="section">
          <div className="container">
            <Title title="Usuários" />
            <div className="columns">
              <div className="column">
                <Link
                  name="Cadastrar Novo Usuário"
                  link="/usuarios/cadastrar"
                />
                {this.state.isLoadingTable ? (
                  <span className="is-loading-custom" />
                ) : (
                  <div>
                    {this.state.msgError !== "" ? (
                      <div className="error">
                        <p>{this.state.msgError}</p>
                      </div>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Nome do Usuário</th>
                            <th>Permissão</th>
                            <th>Status</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listResponse.map((item, i) => (
                            <TableItem
                              key={i}
                              itens={[
                                item.nome,
                                item.scope.admin
                                  ? "Administrador(a)"
                                  : item.scope.user_jardins
                                  ? "Funcionário(a) - Jardins"
                                  : item.scope.user_riomar
                                  ? "Funcionário(a) - Riomar"
                                  : "Sem permissão",
                                item.status === "1"
                                  ? "Habilitado"
                                  : "Desabilitado"
                              ]}
                              nameButton="Editar"
                              linkButton={"/usuarios/editar/" + item.id}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
