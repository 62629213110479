import React from 'react';
import PropTypes from 'prop-types';

import './css/style.scss';

const Radio = (props) => {
  return(
    <div id="radio-component">
      <label className="label">{props.label}</label>
      <input className="is-checkradio" name={props.name} id={props.id1} value={props.value1} type="radio" onChange={props.onChange} defaultChecked={props.defaultChecked1}/>
      <label htmlFor={props.id1}>{props.option1}</label>
      <input className="is-checkradio" name={props.name} id={props.id2} value={props.value2} type="radio" onChange={props.onChange} defaultChecked={props.defaultChecked2}/>
      <label htmlFor={props.id2}>{props.option2}</label>
      {props.threeOption ?
        <input className="is-checkradio" name={props.name} id={props.id3} value={props.value3} type="radio" onChange={props.onChange} defaultChecked={props.defaultChecked3}/>
      :null}
      {props.threeOption ?
        <label htmlFor={props.id3}>{props.option3}</label>
      :null}
    </div>
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  threeOption: PropTypes.bool,
  id1: PropTypes.string.isRequired,
  id2: PropTypes.string.isRequired,
  id3: PropTypes.string,
  value1: PropTypes.any.isRequired,
  value2: PropTypes.any.isRequired,
  value3: PropTypes.any,
  onChange: PropTypes.func,
  // defaultChecked1: PropTypes.bool.isRequired,
  // defaultChecked2: PropTypes.bool.isRequired,
  // defaultChecked3: PropTypes.bool.isRequired,
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired,
  option3: PropTypes.string,
}

export default Radio;