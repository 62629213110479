import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Input from "../../../components/Input";
import Radio from "../../../components/Radio";
import Title from "../../../components/Title";

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      email: "",
      status: true,
      scope: "",

      admin: false,
      user_jardins: false,
      user_riomar: false,

      isLoadingButton: false,
      isLoadingPage: true,
      answer: "",
    };

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  componentDidMount() {
    document.title = "Editar usuário - Balacobaco";
    this.getUser();
  }

  getUser = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/users/${this.props.match.params.id}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        this.setState({ nome: response.data.nome });
        this.setState({ email: response.data.email });
        this.setState({ status: response.data.status === "1" ? true : false });

        if (response.data.scope.admin) {
          this.setState({ admin: true });
          this.setState({ scope: "admin" });
        } else if (response.data.scope.user_jardins) {
          this.setState({ user_jardins: true });
          this.setState({ scope: "user_jardins" });
        } else if (response.data.scope.user_riomar) {
          this.setState({ user_riomar: true });
          this.setState({ scope: "user_riomar" });
        }
      })
      .catch(() => {
        this.props.history.push("/usuarios");
      })
      .finally(() => {
        this.setState({ isLoadingPage: false });
      });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleStatus = (e) => {
    this.handleFormChange(e);
    this.setState({ status: !this.state.status });
  };

  resetPassword = async (e) => {
    this.setState({ isLoadingButton: true });

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.put(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/users/reset-senha/${this.props.match.params.id}`,
      {},
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              check_circle_outline
            </i>,
            "Foi enviada uma solicitação para o e-mail associado de redefinição de senha.",
          ],
        });
      })
      .catch(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao enviar a solicitação de redefinição de senha. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isLoadingButton: true });

    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    let data = {
      nome: "",
      email: "",
      status: "",
      scope: "",
    };

    data.nome = this.state.nome;
    data.email = this.state.email;
    this.state.status ? (data.status = "1") : (data.status = "0");
    data.scope = this.state.scope;

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.put(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/users/${this.props.match.params.id}`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        // this.setState({
        //   answer: [
        //     <i className="material-icons" key="">
        //       check_circle_outline
        //     </i>,
        //     "Usuário editado com sucesso."
        //   ]
        // });
        this.props.history.push("/usuarios");
      })
      .catch(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao editar o usuário. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  render() {
    return (
      <div id="edit-user-view">
        <div className="section">
          <div className="container">
            <Title title="Editar usuário" />
            <Box>
              {this.state.isLoadingPage ? (
                <span className="is-loading-custom" />
              ) : (
                <div className="columns">
                  <div className="column">
                    <form onSubmit={this.handleSubmit}>
                      <div className="columns is-multiline">
                        <div className="column is-8 field">
                          <Input
                            name="nome"
                            label="Nome Completo *"
                            type="text"
                            value={this.state.nome}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "nome",
                              this.state.nome,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-4 field">
                          <Input
                            name="email"
                            label="Email *"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "email",
                              this.state.email,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-2 field">
                          <Checkbox
                            isSingle
                            label="Status: *"
                            option="Habilitado"
                            name="status"
                            id="status"
                            value={this.state.status}
                            defaultChecked={this.state.status}
                            onChange={this.toggleStatus}
                          />
                        </div>
                        <div className="column is-10 field">
                          <Radio
                            threeOption
                            label="Permissão: *"
                            option1="Administrador"
                            option2="Funcionário (Jardins)"
                            option3="Funcionário (Riomar)"
                            name="scope"
                            id1="admin"
                            id2="jardins"
                            id3="riomar"
                            value1="admin"
                            value2="user_jardins"
                            value3="user_riomar"
                            defaultChecked1={this.state.admin}
                            defaultChecked2={this.state.user_jardins}
                            defaultChecked3={this.state.user_riomar}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "scope",
                              this.state.scope,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-12 field reset-password">
                          <button
                            type="button"
                            className="efeito-button"
                            onClick={this.resetPassword}
                          >
                            Resetar senha
                          </button>
                        </div>
                      </div>

                      <div className="columns is-multiline has-text-centered">
                        <div className="column field">
                          <div className="answer">{this.state.answer}</div>
                        </div>
                      </div>

                      <div className="columns is-multiline is-centered">
                        <div className="column is-2 field">
                          <Button name="Voltar" link="/usuarios" isWhite />
                        </div>
                        <div className="column is-2 field">
                          <Button
                            name="Salvar"
                            type="submit"
                            isLoading={this.state.isLoadingButton}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
