import React, { Component } from "react";
import Axios from "axios";
import "./css/style.scss";

import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import TableItem from "../../components/TableItem";
import Title from "../../components/Title";

class Records extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: true,
      msgError: "",
      listResponse: [],
      searchBar: "",

      offset: 0,
      currentPage: 1,
      totalResults: 0
    };
  }

  componentDidMount() {
    document.title = "Histórico de cadastros - Balacobaco";
    this.getRecords();
  }

  getRecords = async () => {
    this.setState({ msgError: "" });

    const idToken = await this.props.userAuth.getIdToken();

    let filter = "";
    if (this.state.searchBar !== "") {
      filter = "&filter=" + this.state.searchBar;
    }

    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/criancas?offset=${
        this.state.offset
      }&limit=10${filter}`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        if (response.data.count === 0) {
          this.setState({
            msgError: "Nenhum cadastro realizado até o momento."
          });
        }
        this.setState({ listResponse: response.data.rows });
        this.setState({ totalResults: response.data.count });
      })
      .catch(error => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca dos cadastros. Tente novamente!"
        });
        console.log(error);
      });

    this.setState({ isLoadingTable: false });
  };

  nextPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset + 10 });
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.getRecords();
    window.scrollTo(0, 0);
  };

  previousPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset - 10 });
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.getRecords();
    window.scrollTo(0, 0);
  };

  goToPage = (page, currentPage) => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: page });
    this.setState({ currentPage: currentPage });
    this.getRecords();
    window.scrollTo(0, 0);
  };

  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoadingTable: true });
    this.setState({ offset: 0 });
    this.setState({ currentPage: 1 });

    this.getRecords();
  };

  render() {
    return (
      <div id="records-view">
        <div className="section">
          <div className="container">
            <Title title="Histórico de cadastros" />

            <SearchBar
              isCentered
              placeholder="Pesquise por nome da criança ou do responsável"
              onSubmit={this.handleSubmit}
              onChange={this.handleFormChange}
              value={this.state.searchBar}
            />

            <div className="columns">
              <div className="column">
                {this.state.isLoadingTable ? (
                  <span className="is-loading-custom" />
                ) : (
                  <div>
                    {this.state.msgError !== "" ? (
                      <div className="error">
                        <p>{this.state.msgError}</p>
                      </div>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Criança</th>
                            <th>Responsável</th>
                            <th>Contato</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listResponse.map((item, i) => (
                            <TableItem
                              key={i}
                              itens={[
                                item.crianca.nome,
                                item.responsavel.nome,
                                item.responsavel.telefone.replace(
                                  /(\d{2})(\d?)(\d{4})(\d{4})/g,
                                  "($1) $2 $3-$4"
                                )
                              ]}
                              nameButton="Detalhes"
                              linkButton={
                                "/cadastros/detalhes/" + item.crianca.id
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    )}

                    <Pagination
                      offset={this.state.offset}
                      currentPage={this.state.currentPage}
                      totalResults={this.state.totalResults}
                      previousPage={this.previousPage}
                      goToPage={this.goToPage}
                      nextPage={this.nextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Records;
