import React, { Component } from "react";
import Axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardTitle,
  ModalCardHeader,
  ModalCardBody,
  ModalCardFooter,
} from "bloomer";
// import Timeago from "react-timeago";
import brStrings from "./formatter";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import moment from "moment";
import "moment/locale/pt-br";
import "./css/style.scss";

import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import ClickOutside from "../../components/ClickOutside";
import Input from "../../components/Input";
import Link from "../../components/Link";
import Radio from "../../components/Radio";
import TableItem from "../../components/TableItem";
import Timeago from "../../components/Timeago";
import Title from "../../components/Title";

class Stays extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listResponse: [],
      qtdEstadias: 0,
      isLoadingTable: true,
      msgError: "",

      listResponseFinished: [],
      qtdEstadiasFinished: 0,
      isLoadingTableFinished: true,
      msgErrorFinished: "",

      modalOpen: false,
      isLoadingModal: true,
      msgErrorModal: "",
      answerModal: "",
      idEstadiaModal: "",
      isLoadingButton: false,
      nomeCrianca: "",
      responsavel: "",
      dataEntrada: "",
      horaEntrada: "",
      horaSaida: "",
      valorCobrado: "",
      valorPago: "",
      desconto: "",

      dataHoraSaida: "",
      pagamento: "",
      codigo: "",

      duracao: "",
      criancasJuntas: "",

      descontoDef: false,
      descontoFunc: false,
      encerrarCriancasJuntas: false,
    };

    this.formatter = buildFormatter(brStrings);

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  componentDidMount() {
    document.title = "Estadias - Balacobaco";
    this.getStays();
    this.getStaysFinished();
  }

  getStays = async () => {
    const idToken = await this.props.userAuth.getIdToken();

    await Axios.get(`${process.env.REACT_APP_API_BALACOBACO_URI}/estadias`, {
      headers: { Authorization: idToken },
    })
      .then((response) => {
        if (response.data.count === 0) {
          this.setState({
            msgError: "Nenhuma estadia cadastrada até o momento.",
          });
        }
        this.setState({ listResponse: response.data.rows });
        this.setState({ qtdEstadias: response.data.count });
      })
      .catch((error) => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca das estadias em aberto. Tente novamente!",
        });
        console.log(error);
      });

    this.setState({ isLoadingTable: false });
  };

  getStaysFinished = async () => {
    const idToken = await this.props.userAuth.getIdToken();

    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/estadias/finalizadas`,
      {
        headers: { Authorization: idToken },
      }
    )
      .then((response) => {
        if (response.data.count === 0) {
          this.setState({
            msgErrorFinished: "Nenhuma estadia finalizada até o momento.",
          });
        }
        this.setState({ listResponseFinished: response.data.rows });
        this.setState({ qtdEstadiasFinished: response.data.count });
      })
      .catch((error) => {
        this.setState({
          msgErrorFinished:
            "Aconteceu algum erro ao realizar a busca das estadias finalizadas. Tente novamente!",
        });
        console.log(error);
      });

    this.setState({ isLoadingTableFinished: false });
  };

  changeModal = async (e, idEstadia) => {
    e.stopPropagation();
    this.setState({ isLoadingModal: true });

    if (this.state.modalOpen) {
      this.setState({ modalOpen: false });
      return;
    } else {
      this.setState({ modalOpen: true });
    }

    await this.goToFinish(idEstadia);
  };

  goToFinish = async (id, filter = "") => {
    const idToken = await this.props.userAuth.getIdToken();

    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/estadias/finalizar/${id}${filter}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        const dataHoraEntrada = response.data.estadia.dataHoraEntrada;
        const dataHoraSaida = response.data.estadia.dataHoraSaida;

        const ddEntrada = dataHoraEntrada.substring(8, 10);
        const mmEntrada = dataHoraEntrada.substring(5, 7);
        const yyyyEntrada = dataHoraEntrada.substring(0, 4);
        const hhEntrada = dataHoraEntrada.substring(11, 13);
        const minEntrada = dataHoraEntrada.substring(14, 16);
        const segEntrada = dataHoraEntrada.substring(17, 19);
        const hhSaida = dataHoraSaida.substring(11, 13);
        const minSaida = dataHoraSaida.substring(14, 16);
        const segSaida = dataHoraSaida.substring(17, 19);

        const dataEntrada = ddEntrada + "/" + mmEntrada + "/" + yyyyEntrada;
        const horaEntrada = hhEntrada + ":" + minEntrada + ":" + segEntrada;
        const horaSaida = hhSaida + ":" + minSaida + ":" + segSaida;

        let duracao = moment
          .utc(
            moment(dataHoraSaida, "YYYY-MM-DD HH:mm:ss").diff(
              moment(dataHoraEntrada, "YYYY-MM-DD HH:mm:ss")
            )
          )
          .format("HH:mm:ss");

        if (response.data.estadia.duracao === 0) {
          duracao = moment().format("00:00:00", "HH:mm:ss");
        }

        this.setState({ duracao });
        this.setState({ nomeCrianca: response.data.crianca.nome });
        this.setState({ responsavel: response.data.responsavel.nome });
        this.setState({ dataEntrada });
        this.setState({ horaEntrada });
        this.setState({ horaSaida });
        this.setState({ dataHoraSaida });
        this.setState({
          valorCobrado: Number(response.data.estadia.valorCobrado).toFixed(2),
        });
        this.setState({ desconto: response.data.estadia.desconto });
        this.setState({ idEstadiaModal: id });
        this.setState({ criancasJuntas: response.data.criancasJuntas });
      })
      .catch((error) => {
        this.setState({
          msgErrorModal:
            "Aconteceu algum erro ao buscar os dados da estadia. Tente novamente!",
        });
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingModal: false });
      });
  };

  goToPayment = async (id) => {
    this.setState({ isLoadingButton: true });

    if (!this.validator.allValid()) {
      this.setState({
        answerModal: [
          <i className="material-icons validation-error" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const data = {
      dataHoraSaida: this.state.dataHoraSaida,
      valorCobrado: this.state.valorCobrado,
      valorPago: this.state.valorPago,
      pagamento: this.state.pagamento,
    };

    if (this.state.encerrarCriancasJuntas) {
      data["criancasJuntas"] = true;
    }

    if (this.state.desconto !== "") {
      data["desconto"] = this.state.desconto;
    }

    if (
      (this.state.pagamento === "credito" && this.state.codigo === "") ||
      (this.state.pagamento === "debito" && this.state.codigo === "")
    ) {
      this.setState({
        answerModal: [
          <i className="material-icons validation-error" key="">
            error_outline
          </i>,
          "É obrigatório preencher o código da transação.",
        ],
      });
      this.setState({ isLoadingButton: false });
      return;
    }

    if (
      (this.state.pagamento === "credito" && this.state.codigo !== "") ||
      (this.state.pagamento === "debito" && this.state.codigo !== "")
    ) {
      data["codigo"] = this.state.codigo;
    }

    const idToken = await this.props.userAuth.getIdToken();

    await Axios.put(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/estadias/pagamento/${this.state.idEstadiaModal}`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(async () => {
        this.setState({ modalOpen: false });
        await this.getStays();
        await this.getStaysFinished();
      })
      .catch((error) => {
        this.setState({
          answerModal: [
            <i className="material-icons validation-error" key="">
              error_outline
            </i>,
            "Aconteceu algum erro ao processar a finalização da estadia. Tente novamente!",
          ],
        });
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
        this.setState({ pagamento: "" });
        this.setState({ codigo: "" });
        this.setState({ valorPago: "" });
        this.setState({ descontoDef: false });
        this.setState({ descontoFunc: false });
        this.setState({ encerrarCriancasJuntas: false });
      });
  };

  handleFormChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  handleCheckboxChange = (e) => {
    this.setState({ isLoadingModal: true });

    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      let filter = "?";
      if (this.state.descontoDef) {
        filter += "desconto=deficiente";
      } else if (this.state.descontoFunc) {
        filter += "desconto=funcionario";
      }

      if (
        (this.state.descontoDef || this.state.descontoFunc) &&
        this.state.encerrarCriancasJuntas
      ) {
        filter += "&";
      }

      if (this.state.encerrarCriancasJuntas) {
        filter += "criancasJuntas=true";
      }

      this.goToFinish(this.state.idEstadiaModal, filter);
    });
  };

  formatMoney = (str) => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  render() {
    return (
      <div id="stays-view">
        <div className="section">
          <div className="container">
            <div>
              <Title
                withData
                title="Estadias"
                date={moment().format("L")}
                time={moment(moment().format()).locale("pt-br").format("HH:mm")}
              />
              <div className="columns">
                <div className="column">
                  {!this.props.userScope.admin ? (
                    <Link
                      name="Criar nova estadia"
                      link="/estadias/cadastrar"
                    />
                  ) : null}
                  <div className="card-initial">
                    <div className="columns">
                      <div className="column">
                        <h2>
                          <i className="material-icons">event_available</i> Em
                          aberto
                        </h2>
                      </div>
                      <div className="column is-right">
                        Total: {this.state.qtdEstadias} criança(s)
                      </div>
                    </div>
                  </div>
                  {this.state.isLoadingTable ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      {this.state.msgError !== "" ? (
                        <div className="error">
                          <p>{this.state.msgError}</p>
                        </div>
                      ) : (
                        <div>
                          <table>
                            <thead>
                              <tr>
                                <th>Criança</th>
                                <th>Hora de Entrada</th>
                                <th>Tempo de Uso</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listResponse.map((item, i) => (
                                <TableItem
                                  isStay
                                  key={i}
                                  itens={[
                                    item.crianca.nome,
                                    `${moment(
                                      moment(item.dataHoraEntrada).format()
                                    )
                                      .locale("pt-br")
                                      .format("HH:mm")}h`,
                                    <Timeago date={item.dataHoraEntrada} />,
                                  ]}
                                  url={"/estadias/detalhes/" + item.id}
                                >
                                  {!this.props.userScope.admin ? (
                                    <button
                                      type="button"
                                      className="efeito-button"
                                      onClick={(e) =>
                                        this.changeModal(e, item.id)
                                      }
                                    >
                                      Finalizar
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </TableItem>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="card-initial">
                    <div className="columns">
                      <div className="column">
                        <h2>
                          <i className="material-icons">event_note</i>{" "}
                          Finalizadas no dia
                        </h2>
                      </div>
                      <div className="column is-right">
                        Total: {this.state.qtdEstadiasFinished} criança(s)
                      </div>
                    </div>
                  </div>
                  {this.state.isLoadingTableFinished ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      {this.state.msgErrorFinished !== "" ? (
                        <div className="error">
                          <p>{this.state.msgErrorFinished}</p>
                        </div>
                      ) : (
                        <div>
                          <table>
                            <thead>
                              <tr>
                                <th>Criança</th>
                                <th>Hora de Entrada</th>
                                <th>Hora de Saída</th>
                                <th>Valor Pago</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listResponseFinished.map(
                                (item, i) => (
                                  <TableItem
                                    isStay
                                    key={i}
                                    itens={[
                                      item.crianca.nome,
                                      `${moment(
                                        moment(item.dataHoraEntrada).format()
                                      )
                                        .locale("pt-br")
                                        .format("HH:mm")}h`,
                                      `${moment(
                                        moment(item.dataHoraSaida).format()
                                      )
                                        .locale("pt-br")
                                        .format("HH:mm")}h`,
                                      this.formatMoney(item.valorPago),
                                    ]}
                                    url={"/estadias/detalhes/" + item.id}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Modal de exclusão */}
                  <Modal isActive={this.state.modalOpen}>
                    <ModalBackground />

                    <ClickOutside
                      onClick={() =>
                        this.setState({
                          modalOpen: false,
                          pagamento: "",
                          codigo: "",
                          descontoDef: false,
                          descontoFunc: false,
                          encerrarCriancasJuntas: false,
                        })
                      }
                      isOpen={this.state.modalOpen}
                    >
                      <ModalCard>
                        <ModalCardHeader>
                          <ModalCardTitle>
                            <div className="columns">
                              <div className="column is-11">
                                <p className="title-modal">Finalizar Estadia</p>
                              </div>
                              <div className="column is-1">
                                <button
                                  type="button"
                                  className="efeito-button"
                                  onClick={() =>
                                    this.setState({
                                      modalOpen: false,
                                      pagamento: "",
                                      codigo: "",
                                      descontoDef: false,
                                      descontoFunc: false,
                                      encerrarCriancasJuntas: false,
                                    })
                                  }
                                >
                                  <i className="material-icons">close</i>
                                </button>
                              </div>
                            </div>
                          </ModalCardTitle>
                        </ModalCardHeader>
                        <ModalCardBody>
                          {this.state.isLoadingModal ? (
                            <span className="is-loading-custom" />
                          ) : (
                            <div>
                              {this.state.msgErrorModal !== "" ? (
                                <div className="error no-margin">
                                  <p>{this.state.msgErrorModal}</p>
                                </div>
                              ) : (
                                <div>
                                  {this.state.criancasJuntas.qtd > 1 ? (
                                    <div>
                                      <div className="columns is-multiline">
                                        <div className="column is-12">
                                          A estadia das crianças{" "}
                                          <strong>
                                            {this.state.criancasJuntas.lista}
                                          </strong>{" "}
                                          foram iniciadas juntas, você deseja
                                          encerrar todas elas juntamente?
                                        </div>
                                        <div className="column">
                                          <Checkbox
                                            option="Encerrar todas as crianças"
                                            name="encerrarCriancasJuntas"
                                            id="encerrarCriancasJuntas"
                                            value={
                                              this.state.encerrarCriancasJuntas
                                            }
                                            defaultChecked={
                                              this.state.encerrarCriancasJuntas
                                            }
                                            onChange={this.handleCheckboxChange}
                                          />
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  ) : null}
                                  <div className="columns">
                                    <div className="column is-4">
                                      <p className="label-modal">Criança:</p>
                                      <p className="answer">
                                        {this.state.nomeCrianca}
                                      </p>
                                    </div>
                                    <div className="column is-8">
                                      <p className="label-modal">
                                        Responsável:
                                      </p>
                                      <p className="answer">
                                        {this.state.responsavel}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="columns">
                                    <div className="column is-4">
                                      <p className="label-modal">
                                        Data de entrada:
                                      </p>
                                      <p className="answer">
                                        {this.state.dataEntrada}
                                      </p>
                                    </div>
                                    <div className="column is-4">
                                      <p className="label-modal">
                                        Hora de entrada:
                                      </p>
                                      <p className="answer">
                                        {this.state.horaEntrada}
                                      </p>
                                    </div>
                                    <div className="column is-4">
                                      <p className="label-modal">
                                        Hora de saída:
                                      </p>
                                      <p className="answer">
                                        {this.state.horaSaida}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="columns">
                                    <div className="column">
                                      <p className="label-modal">
                                        Valor à ser cobrado:
                                      </p>
                                      <p className="answer">
                                        {"R$ " + this.state.valorCobrado}
                                      </p>
                                    </div>
                                    <div className="column">
                                      <p className="label-modal">Duração:</p>
                                      <p className="answer">
                                        {this.state.duracao}
                                      </p>
                                    </div>
                                    <div className="column"></div>
                                  </div>
                                  <div className="columns">
                                    <div className="column is-6">
                                      <Input
                                        name="valorPago"
                                        label="Valor pago (R$) *"
                                        type="number"
                                        value={this.state.valorPago}
                                        onChange={this.handleFormChange}
                                      />
                                      <span>
                                        {this.validator.message(
                                          "valorPago",
                                          this.state.valorPago,
                                          "required"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="columns">
                                    <div className="column">
                                      <Radio
                                        threeOption
                                        label="Forma de pagamento: *"
                                        name="pagamento"
                                        id1="dinheiro"
                                        value1="dinheiro"
                                        option1="Dinheiro"
                                        defaultChecked1={
                                          this.state.pagamento === "dinheiro"
                                        }
                                        id2="debito"
                                        value2="debito"
                                        option2="Débito"
                                        defaultChecked2={
                                          this.state.pagamento === "debito"
                                        }
                                        id3="credito"
                                        value3="credito"
                                        option3="Crédito"
                                        defaultChecked3={
                                          this.state.pagamento === "credito"
                                        }
                                        onChange={this.handleFormChange}
                                      />
                                      <span>
                                        {this.validator.message(
                                          "pagamento",
                                          this.state.pagamento,
                                          "required"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.pagamento === "credito" ||
                                  this.state.pagamento === "debito" ? (
                                    <div className="columns">
                                      <div className="column is-6">
                                        <Input
                                          name="codigo"
                                          label="Código *"
                                          type="text"
                                          value={this.state.codigo}
                                          onChange={this.handleFormChange}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="columns">
                                    <div className="column">
                                      <Checkbox
                                        label="Desconto:"
                                        option="Deficientes"
                                        name="descontoDef"
                                        id="descontoDef"
                                        value={this.state.descontoDef}
                                        defaultChecked={this.state.descontoDef}
                                        onChange={this.handleCheckboxChange}
                                      />
                                      <Checkbox
                                        option="Funcionários"
                                        name="descontoFunc"
                                        id="descontoFunc"
                                        value={this.state.descontoFunc}
                                        defaultChecked={this.state.descontoFunc}
                                        onChange={this.handleCheckboxChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </ModalCardBody>
                        {!this.state.isLoadingModal &&
                        this.state.msgErrorModal === "" ? (
                          <ModalCardFooter>
                            <div className="columns">
                              <div className="column">
                                {this.state.answerModal ? (
                                  <div>
                                    <p className="answer">
                                      {this.state.answerModal}
                                    </p>
                                    <br />
                                  </div>
                                ) : null}
                                <Button
                                  name="Finalizar"
                                  type="button"
                                  onClick={this.goToPayment}
                                  isLoading={this.state.isLoadingButton}
                                />
                              </div>
                            </div>
                          </ModalCardFooter>
                        ) : null}
                      </ModalCard>
                    </ClickOutside>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stays;
