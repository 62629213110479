import React, { Component } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { startOfMonth, endOfMonth, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import "./css/style.scss";

import Link from "../../components/Link";
import Title from "../../components/Title";

class Parties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      msgError: "",
      days: [],
      parties: [],

      currentCalendar: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      }
    };
  }

  componentDidMount() {
    document.title = "Calendário de festas - Balacobaco";

    this.getParties();
  }

  renderCalendar = () => {
    const month = this.state.currentCalendar.month;
    const year = this.state.currentCalendar.year;

    const beforeMonth = month - 1 === 0 ? 12 : month - 1;
    const beforeYear = month - 1 === 0 ? year - 1 : year;
    const beforeRange = this.getMonthDateRange(beforeYear, beforeMonth);

    const range = this.getMonthDateRange(year, month);

    const arrDays = [];

    for (let i = 1; i <= range.dayEndMonth; i++) {

      const date = this.padWithZeroes(i, 2) + '/' + this.padWithZeroes(month, 2) + '/' + year;

      let parties = this.state.parties.filter((party) => {
        return party.dataFesta === date;
      })

      // Popula os dias do mês corrente...
      arrDays.push({
        day: this.padWithZeroes(i, 2),
        date,
        disabled: false,
        parties
      });
    }

    let count = 0;

    // Adição de datas do mês anterior
    switch (range.weekStartMonth) {
      case 'domingo':
        // Nada faz...
        break;
      case 'segunda-feira':
        count = 1;
        break;
      case 'terça-feira':
        count = 2;
        break;
      case 'quarta-feira':
        count = 3;
        break;
      case 'quinta-feira':
        count = 4;
        break;
      case 'sexta-feira':
        count = 5;
        break;
      case 'sábado':
        count = 6;
        break;
      default:
        break;
    }

    // Popula os dias do mês anterior, para completar o calendário no começo...
    for (let i = 0; i < count; i++) {
      arrDays.unshift({
        day: this.padWithZeroes(beforeRange.dayEndMonth - i, 2),
        disabled: true,
      }); 
    }

    // Popula os dias do mês posterior, para completar o calendário no final...
    count = 1;
    for (let i = arrDays.length; i < 42; i++) {
      arrDays.push({
        day: this.padWithZeroes(count, 2),
        disabled: true
      });
      count++;
    }

    this.setState({ days: arrDays });
  }

  padWithZeroes = (number, length) => {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }

    return str;
  }

  getMonthDateRange = (year, month) => {
    const startMonth = startOfMonth(new Date(year, month - 1));
    const endMonth = endOfMonth(new Date(year, month - 1));

    const ddStartMonth = format(startMonth, 'dd');
    const weekStartMonth = format(startMonth, 'cccc', { locale: pt });
    
    const ddEndMonth = format(endMonth, 'dd');
    const weekEndMonth = format(endMonth, 'cccc', { locale: pt });

    return {
      year,
      month,
      dayStartMonth: ddStartMonth,
      weekStartMonth: weekStartMonth,
      dayEndMonth: ddEndMonth,
      weekEndMonth: weekEndMonth,
    }
  }

  getParties = async () => {
    const idToken = await this.props.userAuth.getIdToken();

    const range = this.getMonthDateRange(this.state.currentCalendar.year, this.state.currentCalendar.month);    

    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas/datas`,
      {
        start: range.dayStartMonth + '/' + this.padWithZeroes(range.month, 2) + '/' + range.year,
        end: range.dayEndMonth + '/' + this.padWithZeroes(range.month, 2) + '/' + range.year,
      }, { headers: { Authorization: idToken } }
    )
      .then(response => {
        this.setState({ parties: response.data.data });
      })
      .catch(error => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca das festas. Tente novamente!"
        });
        console.log(error);
      })
      .finally(() => {
        this.renderCalendar();
        this.setState({ isLoading: false });
      });
  };
  
  goToMonth = (type) => {
    const month = this.state.currentCalendar.month;
    const year = this.state.currentCalendar.year;

    switch (type) {
      case 'previous':
        this.setState({
          currentCalendar: {
            month: month - 1 === 0 ? 12 : month - 1,
            year: month - 1 === 0 ? year - 1 : year
          }
        });

        break;
      case 'next':
        this.setState({
          currentCalendar: {
            month: month + 1 === 13 ? 1 : month + 1,
            year: month + 1 === 13 ? year + 1 : year
          }
        });
        break;
      default:
        break;
    }

    this.getParties();
  }

  render() {
    return (
      <div id="parties-view">
        <div className="section">
          <div className="container">
            <Title title="Calendário de festas" />

            <div className="link">
              <Link
                name="Adicionar nova festa"
                link="/festas/cadastrar"
              />
            </div>

            {this.state.isLoading ? (
              <span className="is-loading-custom" />
            ) : (
              <div>
                {this.state.msgError !== "" ? (
                  <div className="error">
                    <p>{this.state.msgError}</p>
                  </div>
                ) : (
                  <>
                    <div className="range-date">
                      <span>
                        <button onClick={() => this.goToMonth('previous')}>
                          <i className="material-icons icon">keyboard_arrow_left</i>
                        </button>
                        {/* <p>{this.state.currentCalendar.month + '/' + this.state.currentCalendar.year}</p> */}
                        <p>{format(new Date(this.state.currentCalendar.year, this.state.currentCalendar.month - 1), 'MMMM', { locale: pt }) + ' de ' + this.state.currentCalendar.year}</p>
                        <button onClick={() => this.goToMonth('next')}>
                          <i className="material-icons icon">keyboard_arrow_right</i>
                        </button>
                      </span>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <div className="box custom-box">
                          <div className="flex header">
                            <div className="item">Domingo</div>
                            <div className="item">Segunda-feira</div>
                            <div className="item">Terça-feira</div>
                            <div className="item">Quarta-feira</div>
                            <div className="item">Quinta-feira</div>
                            <div className="item">Sexta-feira</div>
                            <div className="item">Sábado</div>
                          </div>

                          <div className="flex">
                            {this.state.days.map((data, j) =>
                            <>
                              {data.disabled ?
                                <div className='item' key={j}>
                                  <span className="is-disabled">{data.day}</span>
                                </div>
                              :
                                <NavLink to={'/festas/cadastrar/' + data.date} className='item' key={j}>
                                  <NavLink to={'/festas/lista/' + data.date}>{data.day}</NavLink>
                                  {data.parties && data.parties.length > 0 ?
                                    <p>
                                      {data.parties.map((party) =>
                                        <NavLink to={'/festas/visualizar/' + party.id} key={party.id} className="link">• {party.horaFesta}h | {party.crianca}<br /></NavLink>
                                      )}
                                    </p>
                                  :
                                    ''
                                  }
                                </NavLink>
                              }
                            </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Parties;
