import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./css/style.scss";

const Box = props => (
  <div id="box-component">
    <div className="columns is-centered">
      {props.isLogin ? (
        <div className="column is-3">
          <div className="box">
            <div>{props.children}</div>
          </div>
        </div>
      ) : (
        <div className="column is-12">
          <div
            className={`box ${
              props.hasHeader
                ? "has-header"
                : props.noContainer
                ? "no-container"
                : "no-header"
            }`}
          >
            {props.hasHeader ? (
              <div>
                <div className="header-links">
                  <p>
                    {props.headerLinks.map((link, i) => (
                      <span key={i}>
                        <NavLink className="link" to={link.url}>
                          {link.name}
                        </NavLink>
                        {props.headerLinks.length !== i + 1 ? " > " : null}
                      </span>
                    ))}
                  </p>
                </div>
                <div
                  className={`box-padding ${
                    props.hasHeader && props.noContainer
                      ? "has-header-no-container"
                      : ""
                  }`}
                >
                  {props.children}
                </div>
              </div>
            ) : (
              <div>{props.children}</div>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

Box.propTypes = {
  hasHeader: PropTypes.bool,
  noContainer: PropTypes.bool,
  headerLinks: PropTypes.array,
  children: PropTypes.any.isRequired
};

export default Box;
