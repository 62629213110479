import React from "react";
import Routes from "./routes";
import { hot } from 'react-hot-loader';
import withProvider from "./components/AppContext/withProvider";

const App = () => (
  <div>
    <Routes />
  </div>
);

export default hot(module) (withProvider(App));
// export default App;