import React, { Component } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Subtitle from "../../../components/Subtitle";
import Title from "../../../components/Title";

class ViewParty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: "",

      isActiveModal: false,
      idValue: "",
      isLoadingButton: false,
      answerModal: "",
    };
  }

  componentDidMount() {
    document.title = "Detalhes da festa - Balacobaco";
    this.getParty();
  }

  getParty = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas/${this.props.match.params.id}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        this.setState({ data: response.data });
        if (!response.data.pagamentos) {
          response.data.pagamentos = [];
        }
      })
      .catch(() => {
        this.props.history.push("/festas");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  toggleModal = (id) => {
    this.setState({ isActiveModal: !this.state.isActiveModal });
    this.setState({ idValue: id || "" });
    this.setState({ isLoadingButton: false });
    this.setState({ answerModal: "" });
  };

  deleteValue = async () => {
    this.setState({ isLoadingButton: true });
    this.setState({ answerModal: "" });

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.delete(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas/${this.state.idValue}`,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.toggleModal();
        this.props.history.push("/festas");
      })
      .catch((error) => {
        this.setState({
          answerModal: [
            <i className="material-icons margin-answer" key="">
              error_outline
            </i>,
            "Não foi possível excluir a festa. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  formatMoney = (str) => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  render() {
    return (
      <div id="view-party-view">
        <div className="section">
          <div className="container">
            <Title title="Detalhes da festa" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.isLoading ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      <Subtitle>Dados da Festa</Subtitle>
                      <div className="columns">
                        <div className="column is-3">
                          <p className="label-details">Data:</p>
                          <p className="answer-details">
                            {this.state.data.dataFesta}
                          </p>
                        </div>
                        <div className="column is-3">
                          <p className="label-details">Horário:</p>
                          <p className="answer-details">
                            {this.state.data.horaFesta}
                          </p>
                        </div>
                        <div className="column is-3">
                          <p className="label-details">Valor:</p>
                          <p className="answer-details">
                            {this.formatMoney(this.state.data.valor)}
                          </p>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-3">
                          <p className="label-details">Responsável:</p>
                          <p className="answer-details">
                            {this.state.data.responsavel}
                          </p>
                        </div>
                        <div className="column is-3">
                          <p className="label-details">Criança:</p>
                          <p className="answer-details">
                            {this.state.data.crianca}
                          </p>
                        </div>
                        <div className="column is-3">
                          <p className="label-details">
                            Quantidade de crianças:
                          </p>
                          <p className="answer-details">
                            {this.state.data.qtdCriancas}
                          </p>
                        </div>
                      </div>

                      <br />
                      <Subtitle>Dados de Pagamento</Subtitle>
                      {this.state.data.pagamentos.length === 0 ? (
                        <div className="columns">
                          <div className="column">
                            <p>
                              Nenhum dado de pagamento cadastrado até o momento.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.data.pagamentos.map((pagamento, i) => (
                            <div className="columns">
                              <div className="column is-3">
                                <p className="label-details">Data:</p>
                                <p className="answer-details">
                                  {pagamento.dataPagamento}
                                </p>
                              </div>
                              <div className="column is-3">
                                <p className="label-details">Valor:</p>
                                <p className="answer-details">
                                  {this.formatMoney(pagamento.valor)}
                                </p>
                              </div>
                              <div className="column is-3">
                                <p className="label-details">
                                  Forma de pagamento:
                                </p>
                                <p className="answer-details">
                                  {pagamento.formaPagamento}
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      <div className="columns column-margin">
                        <div className="column is-2">
                          <Button isWhite link="/festas" name="Voltar" />
                        </div>
                        <div className="column is-4"></div>
                        <div className="column is-6 align-right">
                          <NavLink
                            to={"/festas/editar/" + this.props.match.params.id}
                            className="link padding-icon"
                          >
                            <i className="material-icons icon">edit</i> Editar
                          </NavLink>
                          <button
                            type="button"
                            onClick={() =>
                              this.toggleModal(this.props.match.params.id)
                            }
                            className="link efeito-button"
                          >
                            <i className="material-icons icon">delete</i>{" "}
                            Excluir
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>

        <div className={this.state.isActiveModal ? "modal is-active" : "modal"}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Exclusão</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => this.toggleModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              <p>
                Essa ação não pode ser revertida. Você tem certeza que deseja
                efetuar a exclusão?
              </p>
              {this.state.answerModal ? (
                <p className="answer">{this.state.answerModal}</p>
              ) : (
                ""
              )}
            </section>
            <footer className="modal-card-foot">
              <button
                className={
                  this.state.isLoadingButton
                    ? "is-loading button is-success"
                    : "button is-success"
                }
                onClick={() => this.deleteValue()}
              >
                Sim
              </button>
              <button className="button" onClick={() => this.toggleModal()}>
                Não
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewParty;
