import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Title from "../../../components/Title";

class RegisterCashier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valor: "",
      isLoadingPage: true,
      isLoadingButton: false,
      answer: "",
      msgCashier: '',
      labelInput: '',
      msgError: ''
    };

    this.validator = new SimpleReactValidator({
      locale: 'en',
      messages: {
        default: "Campo obrigatório"
      }
    });
  }

  componentDidMount() {
    document.title = "Troca de caixa - Balacobaco";
    if (this.props.userScope.admin) {
      this.setState({ msgError: "Para efetuar a troca de caixa, você deve efetuar o login como funcionário do Jardins ou Riomar." });
      this.setState({ isLoadingPage: false });
      return;
    }
    this.verifyCashier();
  }

  verifyCashier = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(`${process.env.REACT_APP_API_BALACOBACO_URI}/caixa/situacao`, { headers: { Authorization: idToken } })
      .then((res) => {
        this.setState({msgCashier: [
          <i className="material-icons" key="0">attach_money</i>, "Seu caixa atualmente está: ", <strong key="2">{res.data.message}</strong>]
        });
        this.setState({labelInput: res.data.message === 'Aberto' ? 'Valor que foi deixado no caixa' : 'Valor que se encontra no caixa'});
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({isLoadingPage: false});
      })
  };

  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoadingButton: true });
    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos."
        ]
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    let data = {
      valor: this.state.valor || ""
    };

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/caixa`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              check_circle_outline
            </i>,
            "Troca de caixa cadastrada com sucesso."
          ]
        });
      })
      .catch((err) => {
        if(err.response.data.message) {
          this.setState({answer: [<i className="material-icons" key="">error_outline</i>, err.response.data.message]});  
        } else {
          this.setState({answer: [<i className="material-icons" key="">error_outline</i>, "Ocorreu algum erro ao cadastrar a troca de caixa. Tente novamente!"]});
        }
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
        this.verifyCashier();
        this.setState({valor: ''});
      });
  };

  render() {
    return (
      <div id="register-cashier-view">
        <div className="section">
          <div className="container">
            <Title title="Troca de caixa" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.isLoadingPage ?
                    <span className="is-loading-custom" />
                  :
                    <div>
                      {this.state.msgError !== '' ?
                        <p>{this.state.msgError}</p>
                      :
                        <div>
                          <div className="columns is-multiline is-centered">
                            <div className="column is-4 field has-text-centered">
                              <p className="cashier">{this.state.msgCashier}</p>
                            </div>
                          </div>

                          <hr />

                          <form onSubmit={this.handleSubmit}>
                            <div className="columns is-multiline is-centered">
                              <div className="column is-4 field">
                                <Input
                                  name="valor"
                                  label={this.state.labelInput + ' (R$) *'}
                                  type="number"
                                  value={this.state.valor}
                                  onChange={this.handleFormChange}
                                />
                                <span>{this.validator.message('valor', this.state.valor, 'required')}</span>
                              </div>
                            </div>

                            <div className="columns is-multiline has-text-centered">
                              <div className="column field">
                                <div className="answer">{this.state.answer}</div>
                              </div>
                            </div>

                            <div className="columns is-multiline is-centered">
                              <div className="column is-2 field">
                                <Button name="Voltar" link="/usuarios" isWhite />
                              </div>
                              <div className="column is-2 field">
                                <Button name="Salvar" type="submit" isLoading={this.state.isLoadingButton} />
                              </div>
                            </div>
                          </form>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterCashier;
