import React from "react";
import { Switch, Route, HashRouter as Router, Redirect } from "react-router-dom";

import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import withConsumer from "./components/AppContext/withConsumer";

import Cashier from "./views/Cashier/RegisterCashier";
import DailyDetailedReport from "./views/Reports/DailyReports/DailyDetailedReport";
import DailyReports from "./views/Reports/DailyReports";
import Deposits from "./views/Deposits";
import DetailedRecords from "./views/Records/DetailedRecords";
import DetailedStay from "./views/Stays/DetailedStay";
import EditDeposit from "./views/Deposits/EditDeposit";
import EditParty from "./views/Parties/EditParty";
import EditPullout from "./views/Pullouts/EditPullout";
import EditUser from "./views/Users/EditUser";
import EmployeeDetailedReport from "./views/Reports/EmployeeReports/EmployeeDetailedReport";
import EmployeeReports from "./views/Reports/EmployeeReports";
import ForgotPassword from "./views/Login/ForgotPassword";
import ListParty from "./views/Parties/ListParty";
import Login from "./views/Login";
import Logout from "./views/Logout";
import MonthlyDetailedReport from "./views/Reports/MonthlyReports/MonthlyDetailedReport";
import MonthlyReports from "./views/Reports/MonthlyReports";
import NotFound from "./views/404";
import Parties from "./views/Parties";
import Pullouts from "./views/Pullouts";
import ReceiptStay from "./views/Stays/ReceiptStay";
import Records from "./views/Records";
import RegisterDeposit from "./views/Deposits/RegisterDeposit";
import RegisterParty from "./views/Parties/RegisterParty";
import RegisterPullout from "./views/Pullouts/RegisterPullout";
import RegisterStay from "./views/Stays/RegisterStay";
import RegisterUser from "./views/Users/RegisterUser";
import Stays from "./views/Stays";
import Users from "./views/Users";
import ViewParty from "./views/Parties/ViewParty";

const PrivateRoute = withConsumer(
  ({ component: Component, isAdmin, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        if (isAdmin && rest.userScope && !rest.userScope.admin) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        return rest.userAuth ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  )
);

// // Se o usuário estiver deslogado, o header não é mostrado
const CustomHeader = withConsumer(({ ...rest }) =>
  rest.userAuth ? <Header /> : null
);

const Routes = () => (
  <Router>
    <ScrollToTop>
      <main>
        <CustomHeader />
        <div className="main">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/redefinir-senha" component={ForgotPassword} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/cadastros" component={Records} />
            <PrivateRoute exact path="/cadastros/detalhes/:id" component={DetailedRecords} />
            <PrivateRoute exact path="/estadias" component={Stays} />
            <PrivateRoute exact path="/estadias/cadastrar" component={RegisterStay} />
            <PrivateRoute exact path="/estadias/detalhes/:id" component={DetailedStay} />
            <PrivateRoute exact path="/estadias/recibo/:id" component={ReceiptStay} />
            <PrivateRoute exact path="/caixa" component={Cashier} />
            <PrivateRoute isAdmin exact path="/usuarios" component={Users} />
            <PrivateRoute isAdmin exact path="/usuarios/cadastrar" component={RegisterUser} />
            <PrivateRoute isAdmin exact path="/usuarios/editar/:id" component={EditUser} />
            <PrivateRoute isAdmin exact path="/festas" component={Parties} />
            <PrivateRoute isAdmin exact path="/festas/lista/:dd/:mm/:yyyy" component={ListParty} />
            <PrivateRoute isAdmin exact path="/festas/visualizar/:id" component={ViewParty} />
            <PrivateRoute isAdmin exact path="/festas/cadastrar" component={RegisterParty} />
            <PrivateRoute isAdmin exact path="/festas/cadastrar/:dd/:mm/:yyyy" component={RegisterParty} />
            <PrivateRoute isAdmin exact path="/festas/editar/:id" component={EditParty} />
            <PrivateRoute isAdmin exact path="/depositos" component={Deposits} />
            <PrivateRoute isAdmin exact path="/depositos/cadastrar" component={RegisterDeposit} />
            <PrivateRoute isAdmin exact path="/depositos/editar/:id" component={EditDeposit} />
            <PrivateRoute isAdmin exact path="/saidas" component={Pullouts} />
            <PrivateRoute isAdmin exact path="/saidas/cadastrar" component={RegisterPullout} />
            <PrivateRoute isAdmin exact path="/saidas/editar/:id" component={EditPullout} />
            <PrivateRoute isAdmin exact path="/relatorios/funcionarios" component={EmployeeReports} />
            <PrivateRoute isAdmin exact path="/relatorios/funcionarios/:id" component={EmployeeDetailedReport} />
            <PrivateRoute isAdmin exact path="/relatorios/diarios/" component={DailyReports} />
            <PrivateRoute isAdmin exact path="/relatorios/diarios/:local/:data" component={DailyDetailedReport} />
            <PrivateRoute isAdmin exact path="/relatorios/mensais" component={MonthlyReports} />
            <PrivateRoute isAdmin exact path="/relatorios/mensais/:local/:periodo" component={MonthlyDetailedReport} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </main>
    </ScrollToTop>
  </Router>
);

export default Routes;
