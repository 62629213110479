import React, { Component } from "react";
import Axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { Modal, ModalBackground, ModalCard, ModalCardBody } from "bloomer";
import * as EmailValidator from "email-validator";
import CPF from "cpf-check";
import moment from "moment";
import "moment/locale/pt-br";
import "./../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MaskedInput from "../../../components/Input/MaskedInput";
import Subtitle from "../../../components/Subtitle";
import Title from "../../../components/Title";
import Select from "../../../components/Select";

class RegisterStay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Dados do Responsável
      nome: "",
      telefone: "",
      telefoneSeg: "",
      email: "",
      cpf: "",
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      uf: "",
      redeSocial: "",
      uf_list: [
        { label: "AC", value: "AC" },
        { label: "AL", value: "AL" },
        { label: "AM", value: "AM" },
        { label: "AP", value: "AP" },
        { label: "BA", value: "BA" },
        { label: "CE", value: "CE" },
        { label: "DF", value: "DF" },
        { label: "ES", value: "ES" },
        { label: "GO", value: "GO" },
        { label: "MA", value: "MA" },
        { label: "MG", value: "MG" },
        { label: "MS", value: "MS" },
        { label: "MT", value: "MT" },
        { label: "PA", value: "PA" },
        { label: "PB", value: "PB" },
        { label: "PE", value: "PE" },
        { label: "PI", value: "PI" },
        { label: "PR", value: "PR" },
        { label: "RJ", value: "RJ" },
        { label: "RN", value: "RN" },
        { label: "RO", value: "RO" },
        { label: "RR", value: "RR" },
        { label: "RS", value: "RS" },
        { label: "SC", value: "SC" },
        { label: "SE", value: "SE" },
        { label: "SP", value: "SP" },
        { label: "TO", value: "TO" },
      ],
      observacao: "",

      // Dados da Criança
      listCriancas: [
        {
          value: "newChild",
          label: "* Cadastrar nova criança",
        },
      ],
      criancas: [
        {
          idCrianca: "",
          nomeCrianca: "",
          dataNascimento: "",
        },
      ],

      //Festa
      partyId: "",
      parties: "",

      // Outros
      isLoadingButtom: false,
      answer: "",
      msgError: "",
      disabledFields: true,
      modalOpen: false,
    };

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  componentDidMount() {
    document.title = "Criar nova estadia - Balacobaco";
    if (this.props.userScope.admin) {
      this.setState({
        msgError:
          "Para criar as estadias, você deve efetuar o login como funcionário do Jardins ou Riomar.",
      });
      this.setState({ isLoadingTable: false });
      return;
    }
    this.getParties();
  }

  getParties = async () => {
    const idToken = await this.props.userAuth.getIdToken();

    let today = moment().format("L");

    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas?data=${today}`,
      {
        headers: { Authorization: idToken },
      }
    )
      .then((response) => {
        this.setState({
          parties: this.partiesMapper(response.data.rows.reverse()),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAddressByCEP = (e) => {
    this.handleFormChange(e);

    let cep = e.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      this.setState({ modalOpen: true });

      Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          this.setState({ endereco: response.data.logradouro });
          this.setState({ bairro: response.data.bairro });
          this.setState({ cidade: response.data.localidade });
          this.setState({ uf: response.data.uf });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ modalOpen: false });
        });
    } else {
      this.setState({ endereco: "" });
      this.setState({ bairro: "" });
      this.setState({ cidade: "" });
      this.setState({ uf: "" });
    }
  };

  isValidCPF = (cpfInput) => {
    let cpf = cpfInput.replace(/\D/g, "");

    if (CPF.validate(cpf)) {
      return true;
    }
    return false;
  };

  partiesMapper = (parties) => {
    return parties.map((party, key) => {
      let label = party.dataFesta + " - " + party.horaFesta;
      return {
        label: label,
        value: party.id,
      };
    });
  };

  getResponsibleByCPF = async (e) => {
    this.handleFormChange(e);

    let cpf = e.target.value.replace(/\D/g, "");

    if (cpf.length === 11) {
      this.setState({ modalOpen: true });

      const idToken = await this.props.userAuth.getIdToken();
      await Axios.get(
        `${process.env.REACT_APP_API_BALACOBACO_URI}/responsaveis/cpf/${cpf}`,
        { headers: { Authorization: idToken } }
      )
        .then(async (response) => {
          this.setState({ nome: response.data.nome });
          this.setState({ telefone: response.data.telefone });
          if (response.data.telefoneSeg) {
            this.setState({ telefoneSeg: response.data.telefoneSeg });
          }
          if (response.data.email) {
            this.setState({ email: response.data.email });
          }
          this.setState({ cpf: response.data.cpf });
          this.setState({ cep: response.data.cep });
          this.setState({ endereco: response.data.endereco });
          this.setState({ numero: response.data.numero });
          this.setState({ bairro: response.data.bairro });
          if (response.data.complemento) {
            this.setState({ complemento: response.data.complemento });
          }
          this.setState({ cidade: response.data.cidade });
          this.setState({ uf: response.data.uf });
          if (response.data.redeSocial) {
            this.setState({ redeSocial: response.data.redeSocial });
          }
          if (response.data.observacao) {
            this.setState({ observacao: response.data.observacao });
          }

          this.getChildsByCPF(cpf);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ modalOpen: false });
        });

      this.setState({ disabledFields: false });
    } else {
      this.setState({ disabledFields: true });
      this.setState({
        listCriancas: [
          {
            value: "newChild",
            label: "* Cadastrar nova criança",
          },
        ],
      });
      this.setState({
        criancas: [
          {
            idCrianca: "",
            nomeCrianca: "",
            dataNascimento: "",
          },
        ],
      });
    }
  };

  getChildsByCPF = async (cpf) => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/criancas/cpf/${cpf}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          data[i]["value"] = data[i].id;
          data[i]["label"] = data[i].nome + " - " + data[i].dataNascimento;

          delete data[i].id;
          delete data[i].nome;
          delete data[i].dataNascimento;
        }

        // Cria um novo field no select, para cadastro de nova criança
        data.push({ value: "newChild", label: "* Cadastrar nova criança" });

        this.setState({ listCriancas: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addMoreChilds = (e) => {
    e.preventDefault();

    const criancas = [...this.state.criancas];
    criancas.push({
      idCrianca: "",
      nomeCrianca: "",
      dataNascimento: "",
    });
    this.setState({ criancas });
  };

  removeChild = (e, i) => {
    e.preventDefault();

    const criancas = [...this.state.criancas];
    criancas.splice(i, 1);
    this.setState({ criancas });
  };

  createUICriancas() {
    return this.state.criancas.map((el, i) => (
      <div className="columns is-multiline" key={i}>
        <div
          className={`column field ${
            this.state.criancas.length <= 1 ? "is-12" : "is-11"
          }`}
        >
          <Select
            name="idCrianca"
            label="Crianças *"
            options={this.state.listCriancas}
            value={this.state.criancas[i].idCrianca}
            onChange={(e) => this.handleChildChange(e, i)}
            disabled={this.state.disabledFields}
          />
          <span>
            {this.validator.message(
              "idCrianca",
              this.state.criancas[i].idCrianca,
              "required"
            )}
          </span>
        </div>
        {this.state.criancas.length > 1 ? (
          <div className="column field is-1 has-text-centered">
            <button
              type="button"
              className="remove-child efeito-button"
              onClick={(e) => this.removeChild(e, i)}
            >
              <i className="material-icons validation-error" key="">
                remove_circle
              </i>
            </button>
          </div>
        ) : null}
        {this.state.criancas[i].idCrianca === "newChild" ? (
          <div className="column is-8 field">
            <Input
              name="nomeCrianca"
              label="Nome Completo *"
              type="text"
              value={this.state.criancas[i].nomeCrianca}
              onChange={(e) => this.handleChildChange(e, i)}
            />
          </div>
        ) : null}
        {this.state.criancas[i].idCrianca === "newChild" ? (
          <div className="column is-4 field">
            <MaskedInput
              name="dataNascimento"
              label="Data de Nascimento *"
              type="text"
              value={this.state.criancas[i].dataNascimento}
              onChange={(e) => this.handleChildChange(e, i)}
              mask="99/99/9999"
            />
          </div>
        ) : null}
      </div>
    ));
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChildChange = (e, i) => {
    const { name, value } = e.target;

    const arr = this.state.criancas;
    arr[i][name] = value;
    this.setState({ criancas: arr });
  };

  runValidation = () => {
    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons validation-error" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }

    const cpf = this.state.cpf.replace(/\D/g, "");
    if (!this.isValidCPF(cpf)) {
      this.errorValidation("O CPF informado não é válido.");
      return false;
    }

    const telefone = this.state.telefone.replace(/\D/g, "");
    if (telefone.length !== 11) {
      this.errorValidation("O telefone informado não é válido.");
      return false;
    }

    if (this.state.telefoneSeg !== "") {
      const telefoneSeg = this.state.telefoneSeg.replace(/\D/g, "");
      if (telefoneSeg.length !== 11) {
        this.errorValidation("O telefone II informado não é válido.");
        return false;
      }
    }

    if (this.state.email !== "") {
      const email = this.state.email;
      if (!EmailValidator.validate(email)) {
        this.errorValidation("O e-mail informado não é válido.");
        return false;
      }
    }

    const cep = this.state.cep.replace(/\D/g, "");
    if (cep.length !== 8) {
      this.errorValidation("O CEP informado não é válido.");
      return false;
    }

    for (let i = 0; i < this.state.criancas.length; i++) {
      if (this.state.criancas[i].idCrianca === "newChild") {
        const date = moment(
          this.state.criancas[i].dataNascimento,
          "DD/MM/YYYY",
          true
        );
        if (this.state.criancas[i].nomeCrianca === "") {
          this.errorValidation("O nome da criança deve ser preenchido.");
          return false;
        }

        if (this.state.criancas[i].dataNascimento === "") {
          this.errorValidation(
            "A data de nascimento da criança deve ser preenchida."
          );
          return false;
        }

        if (!date.isValid()) {
          this.errorValidation("A data de nascimento informada não é válida.");
          return false;
        }

        if (
          moment(this.state.criancas[i].dataNascimento, "DD/MM/YYYY", true) >
          moment().toDate()
        ) {
          this.errorValidation(
            "A data de nascimento informada é maior que a data atual."
          );
          return false;
        }
      }
    }

    return true;
  };

  errorValidation = (msg) => {
    this.setState({
      answer: [
        <i className="material-icons validation-error" key="">
          error_outline
        </i>,
        msg,
      ],
    });
    this.setState({ isLoadingButton: false });
    return;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingButton: true });
    this.setState({ answer: "" });

    if (!this.runValidation()) {
      return;
    }

    const responsavel = {
      nome: this.state.nome,
      cpf: this.state.cpf.replace(/\D/g, ""),
      telefone: this.state.telefone.replace(/\D/g, ""),
      cep: this.state.cep.replace(/\D/g, ""),
      endereco: this.state.endereco,
      numero: this.state.numero,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      uf: this.state.uf,
    };

    // Campos opicionais
    if (this.state.telefoneSeg !== "") {
      responsavel["telefoneSeg"] = this.state.telefoneSeg.replace(/\D/g, "");
    }
    if (this.state.email !== "") {
      responsavel["email"] = this.state.email;
    }
    if (this.state.complemento !== "") {
      responsavel["complemento"] = this.state.complemento;
    }
    if (this.state.redeSocial !== "") {
      responsavel["redeSocial"] = this.state.redeSocial;
    }
    if (this.state.observacao !== "") {
      responsavel["observacao"] = this.state.observacao;
    }

    const criancas = [];

    for (let i = 0; i < this.state.criancas.length; i++) {
      if (this.state.criancas[i].idCrianca === "newChild") {
        criancas.push({
          nome: this.state.criancas[i].nomeCrianca,
          dataNascimento: this.state.criancas[i].dataNascimento,
        });
      } else {
        criancas.push({
          idCrianca: this.state.criancas[i].idCrianca,
        });
      }
    }

    const idFesta = this.state.partyId;

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/estadias`,
      { responsavel, criancas, idFesta },
      { headers: { Authorization: idToken } }
    )
      .then(async (res) => {
        // nome, cpf, local, data, hora, criancas
        // await this.getReceiptPDF(res.data.estadiaId, idToken);
        this.props.history.push("");
        window.open(`#/estadias/recibo/${res.data.estadiaId}`, "_blank");
        this.props.history.push("/estadias");
      })
      .catch((error) => {
        if (error.response.data.statusCode === 400) {
          this.setState({
            answer: [
              <i className="material-icons validation-error" key="">
                error_outline
              </i>,
              error.response.data.message,
            ],
          });
        } else {
          this.setState({
            answer: [
              <i className="material-icons validation-error" key="">
                error_outline
              </i>,
              "Ocorreu algum erro ao cadastrar a estadia. Tente novamente!",
            ],
          });
        }
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  render() {
    return (
      <div id="register-stay-view">
        <div className="section">
          <div className="container">
            <Title title="Criar nova estadia" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.msgError !== "" ? (
                    <p>{this.state.msgError}</p>
                  ) : (
                    <div>
                      <Subtitle>Dados do Responsável</Subtitle>
                      <div className="columns">
                        <div className="column">
                          <form onSubmit={this.handleSubmit}>
                            <div className="columns is-multiline">
                              <div className="column is-8 field">
                                <Input
                                  name="nome"
                                  label="Nome Completo *"
                                  type="text"
                                  value={this.state.nome}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "nome",
                                    this.state.nome,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <MaskedInput
                                  name="cpf"
                                  label="CPF *"
                                  type="text"
                                  value={this.state.cpf}
                                  onChange={this.getResponsibleByCPF}
                                  mask="999.999.999-99"
                                />
                                <span>
                                  {this.validator.message(
                                    "cpf",
                                    this.state.cpf,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <MaskedInput
                                  name="telefone"
                                  label="Telefone *"
                                  type="text"
                                  value={this.state.telefone}
                                  onChange={this.handleFormChange}
                                  mask="(99) 9 9999-9999"
                                />
                                <span>
                                  {this.validator.message(
                                    "telefone",
                                    this.state.telefone,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <MaskedInput
                                  name="telefoneSeg"
                                  label="Telefone II"
                                  type="text"
                                  value={this.state.telefoneSeg}
                                  onChange={this.handleFormChange}
                                  mask="(99) 9 9999-9999"
                                />
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="email"
                                  label="E-mail"
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.handleFormChange}
                                />
                              </div>
                              <div className="column is-4 field">
                                <MaskedInput
                                  name="cep"
                                  label="CEP *"
                                  type="text"
                                  value={this.state.cep}
                                  onChange={this.getAddressByCEP}
                                  mask="99999-999"
                                />
                                <span>
                                  {this.validator.message(
                                    "cep",
                                    this.state.cep,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="endereco"
                                  label="Endereço *"
                                  type="text"
                                  value={this.state.endereco}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "endereco",
                                    this.state.endereco,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="numero"
                                  label="Número *"
                                  type="text"
                                  value={this.state.numero}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "numero",
                                    this.state.numero,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="bairro"
                                  label="Bairro *"
                                  type="text"
                                  value={this.state.bairro}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "bairro",
                                    this.state.bairro,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-8 field">
                                <Input
                                  name="complemento"
                                  label="Complemento"
                                  type="text"
                                  value={this.state.complemento}
                                  onChange={this.handleFormChange}
                                />
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="cidade"
                                  label="Cidade *"
                                  type="text"
                                  value={this.state.cidade}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "cidade",
                                    this.state.cidade,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <Select
                                  name="uf"
                                  label="UF *"
                                  options={this.state.uf_list}
                                  value={this.state.uf}
                                  onChange={this.handleFormChange}
                                />
                                <span>
                                  {this.validator.message(
                                    "uf",
                                    this.state.uf,
                                    "required"
                                  )}
                                </span>
                              </div>
                              <div className="column is-4 field">
                                <Input
                                  name="redeSocial"
                                  label="Rede Social"
                                  type="text"
                                  value={this.state.redeSocial}
                                  onChange={this.handleFormChange}
                                />
                              </div>
                            </div>

                            <Subtitle>Dados da Criança</Subtitle>
                            <div className="columns is-multiline">
                              <div className="column">
                                {this.createUICriancas()}
                              </div>
                              {!this.state.disabledFields ? (
                                <div className="column field is-12">
                                  <button
                                    type="button"
                                    className="more-childs efeito-button"
                                    onClick={this.addMoreChilds}
                                  >
                                    <i
                                      className="material-icons validation-error"
                                      key=""
                                    >
                                      add_circle
                                    </i>{" "}
                                    Adicionar mais crianças
                                  </button>
                                </div>
                              ) : null}
                              <div className="column field is-12">
                                <Select
                                  name="partyId"
                                  label="Festa"
                                  options={
                                    this.state.parties ? this.state.parties : []
                                  }
                                  value={this.state.partyId}
                                  onChange={this.handleFormChange}
                                />
                              </div>
                              <div className="column field is-12">
                                <Input
                                  name="observacao"
                                  label="Observação"
                                  type="text"
                                  value={this.state.observacao}
                                  onChange={this.handleFormChange}
                                />
                              </div>
                            </div>

                            <div className="columns is-multiline">
                              <div className="column has-text-centered">
                                <div className="answer">
                                  {this.state.answer}
                                </div>
                              </div>
                            </div>

                            <div className="columns is-multiline is-centered">
                              <div className="column is-2 field">
                                <Button
                                  isWhite
                                  name="Voltar"
                                  type="button"
                                  onClick={() =>
                                    this.props.history.push("/estadias")
                                  }
                                />
                              </div>
                              <div className="column is-2 field">
                                <Button
                                  name="Salvar"
                                  type="submit"
                                  isLoading={this.state.isLoadingButton}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* Modal de carregamento */}
                      <Modal isActive={this.state.modalOpen}>
                        <ModalBackground />
                        <ModalCard>
                          <ModalCardBody>
                            <span className="is-loading-custom" />
                          </ModalCardBody>
                        </ModalCard>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterStay;
