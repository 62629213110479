// import type { L10nsStrings } from "../formatters/buildFormatter";

// Brazilian Portuguese
const strings = {
  prefixAgo: null,
  prefixFromNow: "em",
  suffixAgo: null,
  suffixFromNow: null,
  seconds: "Alguns segundos",
  minute: "1 minuto",
  minutes: "%d minutos",
  hour: "1 hora",
  hours: "%d horas",
  day: "1 dia",
  days: "%d dias",
  month: "um mês",
  months: "%d meses",
  year: "1 ano",
  years: "%d anos"
};

export default strings;
