import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import MaskedInput from "../../../components/Input/MaskedInput";
import Subtitle from "../../../components/Subtitle";
import Title from "../../../components/Title";

class EditParty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      horario: "",
      responsavel: "",
      crianca: "",
      valor: "",
      formaPagamento: "",
      qtdCriancas: "",
      pagamentos: [
        {
          dataPagamento: "",
          valor: "",
          formaPagamento: "",
        },
      ],
      isLoadingButton: false,
      isLoadingPage: true,
      answer: "",
    };

    this.validator = new SimpleReactValidator({
      locale: "en",
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  componentDidMount() {
    document.title = "Editar festa - Balacobaco";
    this.getParty();
  }

  getParty = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas/${this.props.match.params.id}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        this.setState({ data: response.data.dataFesta });
        this.setState({ horario: response.data.horaFesta });
        this.setState({ responsavel: response.data.responsavel });
        this.setState({ crianca: response.data.crianca });
        this.setState({ valor: response.data.valor });
        this.setState({ qtdCriancas: response.data.qtdCriancas });
        this.setState({
          pagamentos: response.data.pagamentos || [
            {
              dataPagamento: "",
              valor: "",
              formaPagamento: "",
            },
          ],
        });
      })
      .catch(() => {
        this.props.history.push("/festas");
      })
      .finally(() => {
        this.setState({ isLoadingPage: false });
      });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isLoadingButton: true });

    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos.",
        ],
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const data = {
      data: this.state.data,
      horario: this.state.horario,
      responsavel: this.state.responsavel,
      crianca: this.state.crianca,
      valor: this.state.valor,
      qtdCriancas: this.state.qtdCriancas,
      pagamentos: this.state.pagamentos,
    };

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.put(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/festas/${this.props.match.params.id}`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.props.history.push("/festas");
      })
      .catch(() => {
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao editar a festa. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  createUIPagamentos() {
    return this.state.pagamentos.map((el, i) => (
      <div className="columns is-multiline" key={i}>
        <div className="column field is-4">
          <MaskedInput
            name="dataPagamento"
            label="Data *"
            type="text"
            value={this.state.pagamentos[i].dataPagamento}
            onChange={(e) => this.handleChildChange(e, i)}
            mask="99/99/9999"
          />
          <span>
            {this.validator.message(
              "dataPagamento",
              this.state.pagamentos[i].dataPagamento,
              "required"
            )}
          </span>
        </div>
        <div
          className={`column field ${
            this.state.pagamentos.length <= 1 ? "is-4" : "is-3"
          }`}
        >
          <Input
            name="valor"
            label="Valor *"
            type="number"
            value={this.state.pagamentos[i].valor}
            onChange={(e) => this.handleChildChange(e, i)}
          />
          <span>
            {this.validator.message(
              "valor",
              this.state.pagamentos[i].valor,
              "required"
            )}
          </span>
        </div>
        <div className="column field is-4">
          <Input
            name="formaPagamento"
            label="Forma de pagamento *"
            type="text"
            value={this.state.pagamentos[i].formaPagamento}
            onChange={(e) => this.handleChildChange(e, i)}
          />
          <span>
            {this.validator.message(
              "formaPagamento",
              this.state.pagamentos[i].formaPagamento,
              "required"
            )}
          </span>
        </div>
        {this.state.pagamentos.length > 1 ? (
          <div className="column field is-1 has-text-centered">
            <button
              type="button"
              className="remove-child efeito-button"
              onClick={(e) => this.removeChild(e, i)}
            >
              <i className="material-icons validation-error" key="">
                remove_circle
              </i>
            </button>
          </div>
        ) : null}
      </div>
    ));
  }

  handleChildChange = (e, i) => {
    const { name, value } = e.target;

    const arr = this.state.pagamentos;
    arr[i][name] = value;
    this.setState({ pagamentos: arr });
  };

  addMoreChilds = (e) => {
    e.preventDefault();

    const pagamentos = [...this.state.pagamentos];
    pagamentos.push({
      dataPagamento: "",
      valor: "",
      formaPagamento: "",
    });
    this.setState({ pagamentos });
  };

  removeChild = (e, i) => {
    e.preventDefault();

    const pagamentos = [...this.state.pagamentos];
    pagamentos.splice(i, 1);
    this.setState({ pagamentos });
  };

  render() {
    return (
      <div id="edit-party-view">
        <div className="section">
          <div className="container">
            <Title title="Editar festa" />
            <Box>
              {this.state.isLoadingPage ? (
                <span className="is-loading-custom" />
              ) : (
                <div className="columns">
                  <div className="column">
                    <form onSubmit={this.handleSubmit}>
                      <div className="columns is-multiline">
                        <div className="column is-6 field">
                          <MaskedInput
                            name="data"
                            label="Data *"
                            type="text"
                            value={this.state.data}
                            onChange={this.handleFormChange}
                            mask="99/99/9999"
                          />
                          <span>
                            {this.validator.message(
                              "data",
                              this.state.data,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <MaskedInput
                            name="horario"
                            label="Horário *"
                            type="text"
                            value={this.state.horario}
                            onChange={this.handleFormChange}
                            mask="99:99"
                          />
                          <span>
                            {this.validator.message(
                              "horario",
                              this.state.horario,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="responsavel"
                            label="Responsável *"
                            type="text"
                            value={this.state.responsavel}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "responsavel",
                              this.state.responsavel,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="crianca"
                            label="Criança *"
                            type="text"
                            value={this.state.crianca}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "crianca",
                              this.state.crianca,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="valor"
                            label="Valor da festa *"
                            type="number"
                            value={this.state.valor}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "valor",
                              this.state.valor,
                              "required"
                            )}
                          </span>
                        </div>
                        <div className="column is-6 field">
                          <Input
                            name="qtdCriancas"
                            label="Quantidade de crianças *"
                            type="number"
                            value={this.state.qtdCriancas}
                            onChange={this.handleFormChange}
                          />
                          <span>
                            {this.validator.message(
                              "qtdCriancas",
                              this.state.qtdCriancas,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <Subtitle>Dados de Pagamento</Subtitle>
                          {this.createUIPagamentos()}
                          <div className="column field is-12">
                            <button
                              type="button"
                              className="more-childs efeito-button"
                              onClick={this.addMoreChilds}
                            >
                              <i
                                className="material-icons validation-error"
                                key=""
                              >
                                add_circle
                              </i>{" "}
                              Adicionar mais pagamentos
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="columns is-multiline has-text-centered">
                        <div className="column field">
                          <div className="answer">{this.state.answer}</div>
                        </div>
                      </div>

                      <div className="columns is-multiline is-centered">
                        <div className="column is-2 field">
                          <Button name="Voltar" link="/festas" isWhite />
                        </div>
                        <div className="column is-2 field">
                          <Button
                            name="Salvar"
                            type="submit"
                            isLoading={this.state.isLoadingButton}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default EditParty;
