import React from "react";
import PropTypes from "prop-types";
import "./css/style.scss";

const Checkbox = props => {
  return (
    <div id="checkbox-component">
      <label className="label">{props.label}</label>
      <input
        className="is-checkradio"
        name={props.name}
        id={props.id}
        value={props.value}
        type="checkbox"
        onChange={props.onChange}
        defaultChecked={props.defaultChecked}
      />
      <label htmlFor={props.id}>{props.option}</label>
    </div>
  )
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  option: PropTypes.string.isRequired,
};

export default Checkbox;
