import React from "react";
import { NavLink } from "react-router-dom";
import "./css/style.scss";

const Link = props => (
  <div id="link-component">
    {props.noIcon ? (
      <NavLink to={props.link} className="link">
        <span className="name">{props.name}</span>
      </NavLink>
    ) : props.type === "navbar-button" ? (
      <NavLink to={props.link} className="link navbar-button">
        <i className="material-icons icon">add_circle</i>
        <span className="name">{props.name}</span>
      </NavLink>
    ) : (
      <NavLink to={props.link} className="link">
        {props.onlyIcon ?
          <i className="material-icons icon">{props.typeIcon}</i>
        :
          <div>
            <i className="material-icons icon">add_circle</i>
            <span className="name">{props.name}</span>
          </div>
        }
      </NavLink>
    )}
  </div>
);

export default Link;
