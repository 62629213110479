import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Input from "../../../components/Input";
import Radio from "../../../components/Radio";
import Title from "../../../components/Title";

class RegisterUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      email: "",
      status: true,
      scope: "",
      isLoadingButton: false,
      answer: ""
    };

    this.validator = new SimpleReactValidator({
      locale: 'en',
      messages: {
        default: "Campo obrigatório"
      }
    });
  }
  componentDidMount() {
    document.title = "Cadastrar novo usuário - Balacobaco";
  }
  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleStatus = e => {
    this.handleFormChange(e);
    this.setState({ status: !this.state.status });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoadingButton: true });
    this.setState({ answer: "" });

    if (!this.validator.allValid()) {
      this.setState({
        answer: [
          <i className="material-icons" key="">
            error_outline
          </i>,
          "Erros de validações nos campos."
        ]
      });
      this.setState({ isLoadingButton: false });
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    let data = {
      nome: "",
      email: "",
      status: "",
      scope: ""
    };

    data.nome = this.state.nome;
    data.email = this.state.email;
    this.state.status ? (data.status = "1") : (data.status = "0");
    data.scope = this.state.scope;

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.post(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/users`,
      data,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        // this.setState({
        //   answer: [
        //     <i className="material-icons" key="">
        //       check_circle_outline
        //     </i>,
        //     "Usuário cadastrado com sucesso."
        //   ]
        // });
        this.props.history.push("/usuarios");
      })
      .catch(err => {
        if (err.response.data.statusCode === 409) {
          this.setState({
            answer: [
              <i className="material-icons" key="">
                error_outline
              </i>,
              "O e-mail informado já existe no sistema."
            ]
          });
          return;
        }
        this.setState({
          answer: [
            <i className="material-icons" key="">
              error_outline
            </i>,
            "Ocorreu algum erro ao cadastrar o usuário. Tente novamente!"
          ]
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  render() {
    return (
      <div id="register-user-view">
        <div className="section">
          <div className="container">
            <Title title="Cadastrar novo usuário" />
            <Box>
              <div className="columns">
                <div className="column">
                  <form onSubmit={this.handleSubmit}>
                    <div className="columns is-multiline">
                      <div className="column is-8 field">
                        <Input
                          name="nome"
                          label="Nome Completo *"
                          type="text"
                          value={this.state.nome}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "nome",
                            this.state.nome,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-4 field">
                        <Input
                          name="email"
                          label="Email *"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="column is-2 field">
                        <Checkbox
                          isSingle
                          label="Status: *"
                          option="Habilitado"
                          name="status"
                          id="status"
                          value={this.state.status}
                          defaultChecked={this.state.status}
                          onChange={this.toggleStatus}
                        />
                      </div>
                      <div className="column is-6 field">
                        <Radio
                          threeOption
                          label="Permissão: *"
                          option1="Administrador"
                          option2="Funcionário (Jardins)"
                          option3="Funcionário (Riomar)"
                          name="scope"
                          id1="admin"
                          id2="jardins"
                          id3="riomar"
                          value1="admin"
                          value2="user_jardins"
                          value3="user_riomar"
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "scope",
                            this.state.scope,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="columns is-multiline has-text-centered">
                      <div className="column field">
                        <div className="answer">{this.state.answer}</div>
                      </div>
                    </div>

                    <div className="columns is-multiline is-centered">
                      <div className="column is-2 field">
                        <Button name="Voltar" link="/usuarios" isWhite />
                      </div>
                      <div className="column is-2 field">
                        <Button
                          name="Salvar"
                          type="submit"
                          isLoading={this.state.isLoadingButton}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterUser;
