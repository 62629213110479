import React from "react";
import PropTypes from "prop-types";
import "./css/style.scss";

const Subtitle = props => {
  return (
    <div id="subtitle-component" className="columns">
      <div className="column">
        <p className="subtitle">{props.children}</p>
      </div>
    </div>
  );
};

Subtitle.propTypes = {
  children: PropTypes.string.isRequired
};

export default Subtitle;
