import React, { Component } from "react";
import Axios from "axios";
import * as moment from "moment";
import "moment/locale/pt-br";
import "../css/style.scss";

import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Subtitle from "../../../components/Subtitle";
import Title from "../../../components/Title";

class DetailedStay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      msgError: "",
      data: ""
    };
  }

  componentDidMount() {
    document.title = "Detalhes do cadastro - Balacobaco";
    this.getDetail();
  }

  getDetail = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/responsaveis/estadia/${
        this.props.match.params.id
      }`,
      { headers: { Authorization: idToken } }
    )
      .then(response => {
        const data = response.data;

        let idade = moment().diff(
          moment(data.crianca.dataNascimento, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          "years"
        );
        let idadeCrianca = "";
        if (idade === 1) {
          idadeCrianca = idade + " ano";
        } else {
          idadeCrianca = idade + " anos";
        }

        data.crianca.idade = idadeCrianca;

        this.setState({ data });
      })
      .catch(error => {
        this.setState({ isError: true });
        console.log(error);
      });

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div id="detailed-stay-view">
        <div className="section">
          <div className="container">
            <Title title="Detalhes do cadastro" />
            <Box>
              <div className="columns">
                <div className="column">
                  {this.state.isLoading ? (
                    <span className="is-loading-custom" />
                  ) : (
                    <div>
                      {this.state.msgError !== "" ? (
                        <div className="error">
                          <p>{this.state.msgError}</p>
                        </div>
                      ) : (
                        <div>
                          <Subtitle>Dados do Responsável</Subtitle>
                          <div className="columns">
                            <div className="column is-6">
                              <p className="label-details">Nome:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.nome}
                              </p>
                            </div>
                            <div className="column is-6">
                              <p className="label-details">CPF:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.cpf.replace(
                                  /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                  "$1.$2.$3-$4"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-3">
                              <p className="label-details">Telefone:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.telefone.replace(
                                  /(\d{2})(\d?)(\d{4})(\d{4})/g,
                                  "($1) $2 $3-$4"
                                )}
                              </p>
                            </div>
                            <div className="column is-3">
                              <p className="label-details">Telefone II:</p>
                              {this.state.data.responsavel.telefoneSeg ? (
                                <p className="answer-details">
                                  {this.state.data.responsavel.telefoneSeg.replace(
                                    /(\d{2})(\d?)(\d{4})(\d{4})/g,
                                    "($1) $2 $3-$4"
                                  )}
                                </p>
                              ) : null}
                            </div>
                            <div className="column is-6">
                              <p className="label-details">E-mail:</p>
                              {this.state.data.responsavel.email ? (
                                <p className="answer-details">
                                  {this.state.data.responsavel.email}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-3">
                              <p className="label-details">CEP:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.cep.replace(
                                  /(\d{5})(\d{3})/g,
                                  "$1-$2"
                                )}
                              </p>
                            </div>
                            <div className="column is-3">
                              <p className="label-details">Endereço:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.endereco}
                              </p>
                            </div>
                            <div className="column is-6">
                              <p className="label-details">Número:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.numero}
                              </p>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-3">
                              <p className="label-details">Bairro:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.bairro}
                              </p>
                            </div>
                            <div className="column is-3">
                              <p className="label-details">Complemento:</p>
                              {this.state.data.responsavel.complemento ? (
                                <p className="answer-details">
                                  {this.state.data.responsavel.complemento}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-3">
                              <p className="label-details">Cidade:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.cidade}
                              </p>
                            </div>
                            <div className="column is-3">
                              <p className="label-details">Estado:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.uf}
                              </p>
                            </div>
                            <div className="column is-3">
                              <p className="label-details">Rede Social:</p>
                              <p className="answer-details">
                                {this.state.data.responsavel.redeSocial}
                              </p>
                            </div>
                          </div>
                          <br />

                          <Subtitle>Dados da Criança</Subtitle>
                          <div className="columns">
                            <div className="column is-6">
                              <p className="label-details">Nome:</p>
                              <p className="answer-details">
                                {this.state.data.crianca.nome}
                              </p>
                            </div>
                            <div className="column is-6">
                              <p className="label-details">Idade:</p>
                              <p className="answer-details">
                                {this.state.data.crianca.idade}
                              </p>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <p className="label-details">Observação:</p>
                              {this.state.data.responsavel.observacao ? (
                                <p className="answer-details">
                                  {this.state.data.responsavel.observacao}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-2">
                              <Button isWhite link={`/estadias/recibo/${this.props.match.params.id}`} name="Gerar Recibo" />
                            </div>
                            <div className="column is-2">
                              <Button isWhite link="/estadias" name="Voltar" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailedStay;
