import { Component } from "react";
import * as moment from 'moment';
import 'moment/locale/pt-br';

class Timeago extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.date,
      timer: 0,
    };
  }

  componentDidMount() {
    let dataHoraEntrada = moment(this.props.date).format('DD/MM/YYYY HH:mm:ss');
    let dataHoraAtual = moment().format('DD/MM/YYYY HH:mm:ss');

    var ms = moment(dataHoraAtual,"DD/MM/YYYY HH:mm:ss").diff(moment(dataHoraEntrada,"DD/MM/YYYY HH:mm:ss"));
    if (ms >= 0) {
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    } else {
      s = moment().format('00:00:00','HH:mm:ss');
    }

    this.setState({ timer: s });
    this.startTimer();
  }

  startTimer = () => {
    setInterval(() => {

      let dataHoraEntrada = moment(this.props.date).format('DD/MM/YYYY HH:mm:ss');
      let dataHoraAtual = moment().format('DD/MM/YYYY HH:mm:ss');

      var ms = moment(dataHoraAtual,"DD/MM/YYYY HH:mm:ss").diff(moment(dataHoraEntrada,"DD/MM/YYYY HH:mm:ss"));
      if (ms >= 0) {
        var d = moment.duration(ms);
        var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
      } else {
        s = moment().format('00:00:00','HH:mm:ss');
      }

      this.setState({ timer: s });
    }, 1000);
  }

  render() {
    return this.state.timer
  }
}

export default Timeago;
