import React, { Component } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import "./css/style.scss";

import Link from "../../components/Link";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import TableItem from "../../components/TableItem";
import Title from "../../components/Title";

class Pullouts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: true,
      msgError: "",
      listResponse: [],
      searchBar: "",

      offset: 0,
      currentPage: 1,
      totalResults: 0,

      isActiveModal: false,
      idValue: "",
      isLoadingButton: false,
      answerModal: "",
    };
  }

  componentDidMount() {
    document.title = "Controle de saídas - Balacobaco";
    this.getParties();
  }

  toggleModal = (id) => {
    this.setState({ isActiveModal: !this.state.isActiveModal });
    this.setState({ idValue: id || "" });
    this.setState({ isLoadingButton: false });
    this.setState({ answerModal: "" });
  };

  getParties = async () => {
    this.setState({ msgError: "" });

    const idToken = await this.props.userAuth.getIdToken();

    let filter = "";
    if (this.state.searchBar !== "") {
      filter = "&filter=" + this.state.searchBar;
    }

    await Axios.get(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas?offset=${this.state.offset}&limit=10${filter}`,
      { headers: { Authorization: idToken } }
    )
      .then((response) => {
        if (response.data.count === 0) {
          this.setState({
            msgError: "Nenhum cadastro realizado até o momento.",
          });
        }
        this.setState({ listResponse: response.data.rows });
        this.setState({ totalResults: response.data.count });
      })
      .catch((error) => {
        this.setState({
          msgError:
            "Aconteceu algum erro ao realizar a busca dos cadastros. Tente novamente!",
        });
        console.log(error);
      });

    this.setState({ isLoadingTable: false });
  };

  nextPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset + 10 });
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.getParties();
    window.scrollTo(0, 0);
  };

  previousPage = () => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: this.state.offset - 10 });
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.getParties();
    window.scrollTo(0, 0);
  };

  goToPage = (page, currentPage) => {
    this.setState({ isLoadingTable: true });
    this.setState({ offset: page });
    this.setState({ currentPage: currentPage });
    this.getParties();
    window.scrollTo(0, 0);
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isLoadingTable: true });
    this.setState({ offset: 0 });
    this.setState({ currentPage: 1 });

    this.getParties();
  };

  deleteValue = async () => {
    this.setState({ isLoadingButton: true });
    this.setState({ answerModal: "" });

    const idToken = await this.props.userAuth.getIdToken();
    await Axios.delete(
      `${process.env.REACT_APP_API_BALACOBACO_URI}/saidas/${this.state.idValue}`,
      { headers: { Authorization: idToken } }
    )
      .then(() => {
        this.toggleModal();
        this.getParties();
      })
      .catch((error) => {
        this.setState({
          answerModal: [
            <i className="material-icons margin-answer" key="">
              error_outline
            </i>,
            "Não foi possível excluir a saída. Tente novamente!",
          ],
        });
      })
      .finally(() => {
        this.setState({ isLoadingButton: false });
      });
  };

  formatMoney = (str) => {
    const value = parseFloat(str);

    const formatValue = value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const returnValue = "R$ " + formatValue.toString();

    return returnValue;
  };

  compare(a, b) {
    var a1 = a.dataSaida.split("/");
    var b1 = b.dataSaida.split("/");
    a1 = new Date(`${a1[1]}-${a1[0]}-${a1[2]}`);
    b1 = new Date(`${b1[1]}-${b1[0]}-${b1[2]}`);
    return b1 - a1;
  }

  render() {
    return (
      <div id="parties-view">
        <div className="section">
          <div className="container">
            <Title title="Controle de saídas" />

            <div className="link">
              <Link name="Adicionar nova saída" link="/saidas/cadastrar" />
            </div>

            <SearchBar
              isCentered
              placeholder="Pesquise por data"
              onSubmit={this.handleSubmit}
              onChange={this.handleFormChange}
              value={this.state.searchBar}
            />

            <div className="columns">
              <div className="column">
                {this.state.isLoadingTable ? (
                  <span className="is-loading-custom" />
                ) : (
                  <div>
                    {this.state.msgError !== "" ? (
                      <div className="error">
                        <p>{this.state.msgError}</p>
                      </div>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>Valor</th>
                            <th>Motivo</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listResponse
                            .sort(this.compare)
                            .map((item, i) => (
                              <TableItem
                                key={i}
                                itens={[
                                  item.dataSaida,
                                  this.formatMoney(item.valor),
                                  item.motivo,
                                ]}
                                moreLinks={
                                  <div>
                                    {this.props.userScope.admin ? (
                                      <span>
                                        <NavLink
                                          to={"/saidas/editar/" + item.id}
                                          className="link padding-icon"
                                        >
                                          <i className="material-icons icon">
                                            edit
                                          </i>
                                        </NavLink>
                                        <button
                                          type="button efeito-button"
                                          onClick={() =>
                                            this.toggleModal(item.id)
                                          }
                                          className="link"
                                        >
                                          <i className="material-icons icon">
                                            delete
                                          </i>
                                        </button>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                }
                              />
                            ))}
                        </tbody>
                      </table>
                    )}

                    <Pagination
                      offset={this.state.offset}
                      currentPage={this.state.currentPage}
                      totalResults={this.state.totalResults}
                      previousPage={this.previousPage}
                      goToPage={this.goToPage}
                      nextPage={this.nextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.isActiveModal ? "modal is-active" : "modal"}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Exclusão</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => this.toggleModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              <p>
                Essa ação não pode ser revertida. Você tem certeza que deseja
                efetuar a exclusão?
              </p>
              {this.state.answerModal ? (
                <p className="answer">{this.state.answerModal}</p>
              ) : (
                ""
              )}
            </section>
            <footer className="modal-card-foot">
              <button
                className={
                  this.state.isLoadingButton
                    ? "is-loading button is-success"
                    : "button is-success"
                }
                onClick={() => this.deleteValue()}
              >
                Sim
              </button>
              <button className="button" onClick={() => this.toggleModal()}>
                Não
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default Pullouts;
