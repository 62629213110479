import React, { Component } from "react";
import Axios from "axios";
import "./css/style.scss";

import logo from "../../../assets/img/balacobaco_web_logo.png";

class ReceiptStay extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    document.title = "Recibo - Balacobaco";
    this.getDetail();
  }

  getDetail = async () => {
    const idToken = await this.props.userAuth.getIdToken();
    await Axios.get(`${process.env.REACT_APP_API_BALACOBACO_URI}/estadias/recibo/${this.props.match.params.id}`, { headers: { Authorization: idToken } })
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    const Recibo = () => (
      <>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <h2>Recibo</h2>
        <p><b>Responsável:</b> {this.state.data.responsavel.nome}</p>
        <p><b>Crianças:</b> {this.state.data.criancas}</p>
        <p><b>CPF:</b> {this.state.data.responsavel.cpf}</p>
        <hr className="hr-top" />
        <p>Entrada: <b>{this.state.data.dataEntrada} {this.state.data.horaEntrada}</b></p>
        <hr className="hr-bottom" />
        <p><b>Importante:</b></p>
        <p>1 - O RESPONSÁVEL CONTROLA O TEMPO DE PERMANÊNCIA DA CRIANÇA.</p>
        <p>2 - APRESENTE ESTE TICKET PARA RETIRAR A CRIANÇA.</p>
        <hr className="hr-top-bottom" />
        <div className="footer-print">
          <p><b>Esse cupom não possui valor fiscal</b></p>
          <p>Balacobaco - {this.state.data.local}</p>
        </div>
      </>
    );

    return (
      <div id="receipt-stay-view">
        <div id="non-printable">
          <div className="container">
            {this.state.data ?
              <div className="columns">
                <div className="column is-center">
                  <button className="button" onClick={() => window.print()}><i className="material-icons validation-error">print</i> Imprimir</button>
                </div>
              </div>
            :null}
            <div className="columns">
              <div className="column">
                <div className="card-custom">
                  {!this.state.data ?
                    <span className="is-loading-custom" />
                  :
                    <>
                      <Recibo />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.data ?
          <div id="printable">
            <Recibo />
          </div>
        :null}
      </div>
    );
  }
}

export default ReceiptStay;
